import SevenImgObject from '@/models/seven_img_object';
import Reaction from '@/models/reaction';

class ModulePagination {
  constructor({ currentModuleId, nextModuleId, prevModuleId }) {
    this.currentModuleId = currentModuleId;
    this.nextModuleId = nextModuleId;
    this.prevModuleId = prevModuleId;
  }

  static fromJson(data) {
    return new ModulePagination({
      currentModuleId: data['current_module_id'],
      nextModuleId: data['next_module_id'],
      prevModuleId: data['prev_module_id'],
    });
  }
}

export default class LearnModule {
  constructor({
    id,
    pagination,
    userReactions,
    coverUrl,
    connectedUserFavorited,
    contentType,
    creatorId,
    deletedAt,
    duration,
    editorContentId,
    publishedAt,
    reactionType,
    status,
    submissionStatus,
    submissionUpdatedAt,
    themes,
    title,
    updatedAt,
  }) {
    this.id = id;
    if (pagination) {
      this.pagination = ModulePagination.fromJson(pagination);
    }
    if (userReactions) {
      this.userReactions = [];
      userReactions.forEach((item) => this.userReactions.push(Reaction.fromJson(item)));
    }
    if (coverUrl) {
      this.coverUrl = SevenImgObject.fromJson(coverUrl);
    }
    this.connectedUserFavorited = connectedUserFavorited;
    this.contentType = contentType;
    this.creatorId = creatorId;
    this.deletedAt = deletedAt;
    this.duration = duration;
    this.editorContentId = editorContentId;
    this.publishedAt = publishedAt;
    this.reactionType = reactionType;
    this.status = status;
    this.submissionStatus = submissionStatus;
    this.submissionUpdatedAt = submissionUpdatedAt;
    this.themes = themes;
    this.title = title;
    this.updatedAt = updatedAt;
  }

  static fromJson(data) {
    return new LearnModule({
      id: data?.['id'],
      pagination: data?.['pagination'],
      userReactions: data?.['user_reactions'],
      coverUrl: data?.['cover_url'],
      connectedUserFavorited: data?.['connected_user_favorited'],
      contentType: data?.['content_type'],
      creatorId: data?.['creator_id'],
      deletedAt: data?.['deleted_at'],
      duration: data?.['duration'],
      editorContentId: data?.['editor_content_id'],
      publishedAt: data?.['published_at'],
      reactionType: data?.['reaction_type'],
      status: data?.['status'],
      submissionStatus: data?.['submission_status'],
      submissionUpdatedAt: data?.['submission_updated_at'],
      themes: data?.['themes'],
      title: data?.['title'],
      updatedAt: data?.['updated_at'],
    });
  }
}
