<template>
  <svn-pro-data-table
    :headers="interviewForms?.length ? headersTmp : []"
    :items="interviewForms ?? []"
    :items-per-page="-1"
    :page="pagination?.current_page"
    :loading="interviewForms ? loading : true"
    item-value="id"
    :pagination-total-pages="pagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr
      class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]"
      @click="$router.push({
        name: 'interviews_template_edit',
        params: { id: item?.id },
      })"
      >
        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="resizeTitle(item?.title)"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$t(interviewFormTypeToStr[item?.template_type])"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.interview_question_count"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatDate(item?.updated_at)"
          />
        </td>

        <td>
          <display-tag-in-index :tags="item?.categories" />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>

              <dialog-edit-template-tags
                fetch-tags-from-entity-path="categories_from_template"
                route="forms/templates"
                :entity-id="item?.id"
                :template-categories="item.categories"
                @update-interview-forms-tags="updateInterviewFormsTags"
              >
                <template #activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    value="edit_tags"
                  >
                    {{ $t('Edit template tags') }}
                  </v-list-item>
                </template>
              </dialog-edit-template-tags>

              <v-list-item
                value="duplicate"
                @click="duplicate(item?.id)"
              >
                {{ $t('Duplicate') }}
              </v-list-item>

              <svn-pro-dialog-validation
                :max-width="560"
                icon="noto:warning"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('Delete')"
                :title="$t('Template will be deleted')"
                :content-text="$t('Deleted templates are stored for 30 days. After this period, they will be permanently deleted.')"
                @click-primary-button="deleteInterviewFormPop(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item v-bind="props" :active="false" density="compact" value="delete_template">
                    <svn-pro-text body-large regular color="error">
                      {{ $t('Delete') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </svn-pro-dialog-validation>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>
    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :action-primary-title="$t('Create template')"
        prepend-primary="custom:mingcute:add-line"
        supporting-text="It seems you have no template for the moment."
        @click-primary="createTemplate"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { useInterviewFormStore } from "@/store/interview-form.js";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { usePopUpStore } from "@/store/pop-up";
import DisplayTagInIndex from "@/components/DisplayTagInIndex.vue";
import axiosService from "@/tools/axios-service.js";
import { ref } from "vue";
import { interviewFormTypeToStr } from "@/constants/types.js";
import { useRouter } from "vue-router";
import i18n from "@/plugins/i18n";
import DialogEditTemplateTags from "@/components/BktPopUp/Dialogs/DialogEditTemplateTags.vue";
import {useMobileStore} from "@/store/mobile.js";
import { useSnackbar } from "@/store/snackbar";

const props = defineProps({
  search: { type: String, default: '' },
})

const emit = defineEmits(['create-template'])

const snackbar = useSnackbar();
const { isMdScreen } = storeToRefs(useMobileStore());

const router = useRouter();

const headersTmp = [
{
    align: "start",
    key: "title",
    sortable: false,
    title: i18n.global.t('Template title'),
    minWidth: 300,
  },
  {
    align: "start",
    key: "type",
    sortable: false,
    title: i18n.global.t('Type'),
    minWidth: 150,
  },
  {
    align: "start",
    key: "questions",
    sortable: false,
    title: i18n.global.t('Questions'),
    minWidth: 100,
  },
  {
    align: "start",
    key: "last_update",
    sortable: false,
    title: i18n.global.t('Last Update'),
    minWidth: 100,
  },
  {
    align: "start",
    key: "tags",
    sortable: false,
    title: i18n.global.t('Tags'),
    minWidth: 200,
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
    minWidth: 50
  }
];

const editModal = ref(false);
const {isLargeScreen} = useMobileStore()


const { openPopUp, closePopUp } = usePopUpStore();
const { interviewForms, pagination, loading } = storeToRefs(useInterviewFormStore());
const { updateParams, fetchInterviewForms, deleteInterviewForm, fetchCampaignDraftsExist } =
  useInterviewFormStore();

const duplicate = async (id) => {
  try {
    const res = await axiosService.post(
      `/api/v2/interview_app/forms/templates/${id}/duplicate`
    );

    await router.push({
      name: "interviews_template_edit",
      params: { id: res.data.template_id },
    });
  } catch (e) {
    console.log("error", e);
  }
};
const resizeTitle = (title) => {
  if (!title) return title
  const maxChars = isLargeScreen.value ? 30 : 20
  return title.length > maxChars ? title.substring(0, maxChars) + '...' : title
};

const createTemplate = () => {
  emit('createTemplate')
}

const deleteInterviewFormPop = async(id) => {
  try {
    const campaignDraftsExist = await fetchCampaignDraftsExist(id)

    if (campaignDraftsExist) {
      await deleteInterviewForm(id);
      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Template has been deleted successfully.')
      snackbar.displaySnackBar()
    }
    else {
      await deleteInterviewForm(id);
      snackbar.setBgColor('onSurface');
      snackbar.setMsg('Template has been deleted successfully.')
      snackbar.setCustomClass(isMdScreen.value ? 'mb-0' : 'mb-[80px]')
      snackbar.displaySnackBar()
    }
  } catch (error) {
    console.log(error);
  }
};

const fetchNewPage = (e) => {
  updateParams({
    "page[number]": e,
  });
  fetchInterviewForms();

};

const updateInterviewFormsTags = (id, tag) => {
  interviewForms.value.forEach((form) => {
    if (form.id === id) {
      const formTags = form.categories
      const formTag = formTags.find(t => t.id === tag.id)
      if (formTag) {
        form.categories = form.categories.filter(t => t.id !== tag.id)
        console.log(formTag)
      } else {
        form.categories.push(tag)
      }
    }
  })
}
</script>
