import LearnInput from './learn_input';

export default class BaseEvaluation {
  constructor({ id, learnInputs }) {
    this.id = id;
    this.learnInputs = learnInputs ? learnInputs.map(LearnInput.fromJson) : [];
  }

  static fromJson(data) {
    return new this({
      id: data.id,
      learnInputs: data.learn_inputs,
    });
  }
}
