<template>
  <div class="w-full flex flex-col gap-6 p-4 lg:!p-6 rounded-[12px] border border-borderColor border-opacity-100">
    <my-interview-card-header
      :campaign-deadline="campaignDeadline"
      :campaign-type="campaignType"
      :campaign-name="campaignTitle"
    />

    <template
      v-for="(interview, index) in campaignInterviews"
      :key="interview?.id"
    >
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-8">
          <div class="flex items-center justify-between">
            <div class="w-full flex flex-col gap-4 md:!flex-row md:!gap-16">
              <!-- Interviewee -->
              <div
                v-if="interview?.interview_set?.employee_interview !== null"
                class="w-full flex items-center justify-between md:!w-fit"
              >
                <div class="lg:!w-[224px] flex items-center gap-2">
                  <svn-pro-avatar
                    :size="40"
                    :firstname="interview?.employee?.fullname.split(' ')[1]"
                    :image="interview?.employee?.avatar?.['50']"
                    :type="interview?.employee?.avatar?.['50'] ? 'photo' : 'monogram'"
                  />

                  <div class="flex flex-col">
                    <svn-pro-text subtitle-large medium class="line-clamp-1 break-all">
                      {{ interview?.employee?.fullname }}
                    </svn-pro-text>

                    <interview-status-chip :status="interview?.interview_set?.employee_interview?.status" />
                  </div>
                </div>

                <!-- Menu -->
                <div class="md:hidden">
                  <svn-pro-menu>
                    <template #activator>
                      <svn-pro-icon-button
                        id="activatorMobile"
                        icon="custom:mingcute:more-2-line"
                        color="onSurfaceVariant"
                        variant="text"
                      />
                    </template>

                    <template #dropdown>
                      <!-- See participant profile -->
                      <v-list-item
                        v-bind="props"
                        :active="false"
                        value="see_participant_profile"
                        :to="'/people/user_show/' + interview?.employee?.id"
                        target="_blank"
                      >
                        {{ $t('See participant profile') }}
                      </v-list-item>

                      <!-- Send Invitation -->
                      <v-list-item
                        :active="false"
                        value="send_invitation_email"
                        @click="sendInvitation(interview?.employee?.id)"
                      >
                        {{ $t('Send invitation email') }}
                      </v-list-item>

                      <!-- Send Reminder -->
                      <v-list-item
                        :active="false"
                        value="send_reminder_email"
                        @click="sendReminder(interview?.employee?.id)"
                      >
                      {{ $t('Send reminder email') }}
                      </v-list-item>

                      <!-- Set person in charge -->
                      <pop-up-set-another-interviewer
                        :campaign-id="campaignId"
                        :employee-id="interview?.employee?.id"
                        @refetch-campaigns="callback"
                      >
                        <template #activator="{ props }">
                          <v-list-item
                            v-bind="props"
                            :active="false"
                            value="set_person_incharge"
                          >
                          {{ $t('Set another person in charge') }}
                          </v-list-item>
                        </template>
                      </pop-up-set-another-interviewer>

                      <!-- Schedule a meeting -->
                      <pop-up-shift-cross-interview-date
                        v-if="interview.label === InterviewOneToOneLabels.CROSSED && interview.status !== InterviewStatuses.SUBMITTED"
                        :campaign-id="campaignId"
                        :cross-id="interview?.interview_set?.crossed_interview?.id"
                        :start-date="interview?.interview_set?.crossed_interview?.startDate"
                        :end-date="interview?.interview_set?.crossed_interview?.endDate"
                        :date="interview?.interview_set?.crossed_interview?.date"
                        @callback="fetchCampaign(campaignId)"
                      >
                        <template #activator="{ props }">
                          <v-list-item
                            v-bind="props"
                            :active="false"
                            value="schedule_meeting"
                          >
                          {{ $t('Schedule a meeting') }}
                          </v-list-item>
                        </template>
                      </pop-up-shift-cross-interview-date>
                    </template>
                  </svn-pro-menu>
                </div>
              </div>

              <!-- Interviewer -->
              <div
                v-if="interview?.interview_set?.manager_interview !== null"
                class="lg:!w-[160px] flex flex-col"
              >
                <svn-pro-text subtitle-large medium>
                  {{ $t('Interviewer (you)') }}
                </svn-pro-text>

                <interview-status-chip :status="interview?.interview_set?.manager_interview?.status" />
              </div>

              <!-- Cross Review -->
              <div
                v-if="interview?.interview_set?.manager_interview !== null && interview?.interview_set?.crossed_interview !== null"
                class="lg:!w-[200px] flex flex-col"
              >
                <svn-pro-text subtitle-large medium>
                  {{ $t('Cross Review') }}
                </svn-pro-text>

                <interview-status-chip :status="interview?.interview_set?.crossed_interview?.status" />
              </div>
            </div>

            <!-- Menu -->
            <div class="hidden md:!flex">
              <svn-pro-menu>
                <template #activator>
                  <svn-pro-icon-button
                    id="activatorDesktop"
                    icon="custom:mingcute:more-2-line"
                    color="onSurfaceVariant"
                    variant="text"
                  />
                </template>

                <template #dropdown>
                  <!-- See participant profile -->
                  <v-list-item
                    v-bind="props"
                    :active="false"
                    value="see_participant_profile"
                    :to="'/people/user_show/' + interview?.employee?.id"
                    target="_blank"
                  >
                    {{ $t('See participant profile') }}
                  </v-list-item>

                  <!-- Send Invitation -->
                  <v-list-item
                    :active="false"
                    value="send_invitation_email"
                    @click="sendInvitation(interview?.employee?.id)"
                  >
                    {{ $t('Send invitation email') }}
                  </v-list-item>

                  <!-- Send Reminder -->
                  <v-list-item
                    :active="false"
                    value="send_reminder_email"
                    @click="sendReminder(interview?.employee?.id)"
                  >
                  {{ $t('Send reminder email') }}
                  </v-list-item>

                  <!-- Set person in charge -->
                  <pop-up-set-another-interviewer
                    :campaign-id="campaignId"
                    :employee-id="interview?.employee?.id"
                    @refetch-campaigns="callback"
                  >
                    <template #activator="{ props }">
                      <v-list-item
                        v-bind="props"
                        :active="false"
                        value="set_person_incharge"
                      >
                      {{ $t('Set another person in charge') }}
                      </v-list-item>
                    </template>
                  </pop-up-set-another-interviewer>

                  <!-- Schedule a meeting -->
                  <pop-up-shift-cross-interview-date
                    v-if="interview.status !== InterviewStatuses.SUBMITTED"
                    :campaign-id="campaignId"
                    :cross-id="interview?.interview_set?.crossed_interview?.id"
                    :start-date="interview?.interview_set?.crossed_interview?.startDate"
                    :end-date="interview?.interview_set?.crossed_interview?.endDate"
                    :date="interview?.interview_set?.crossed_interview?.date"
                    @callback="fetchCampaign(campaignId)"
                  >
                    <template #activator="{ props }">
                      <v-list-item
                        v-bind="props"
                        :active="false"
                        value="schedule_meeting"
                      >
                      {{ $t('Schedule a meeting') }}
                      </v-list-item>
                    </template>
                  </pop-up-shift-cross-interview-date>
                </template>
              </svn-pro-menu>
            </div>
          </div>

          <!-- Buttons Cross -->
          <div
            v-if="interview?.interview_set?.manager_interview !== null && interview?.interview_set?.crossed_interview !== null"
            class="flex flex-col gap-3 lg:!flex-row lg:!self-end"
          >
            <svn-pro-button
              v-if="interview?.interview_set?.crossed_interview?.status === 'not_started' &&
                interview?.interview_set?.employee_interview?.status === 'submitted' ||
                interview?.interview_set?.employee_interview?.status === 'submitted' &&
                interview?.interview_set?.crossed_interview?.status === 'not_available_yet'"
              :to="{
                name: 'interview_answer',
                params: { id: interview?.interview_set?.employee_interview?.id },
              }"
              variant="outlined"
              :text="$t(`View interviewee's answers`)"
            />

            <svn-pro-button
              v-if="interview?.interview_set?.manager_interview?.status === 'submitted'"
              :to="{
                name: 'interview_answer',
                params: { id: interview?.interview_set?.manager_interview?.id },
              }"
              variant="outlined"
              :text="$t(`View my answers`)"
            />

            <svn-pro-button
              v-if="interview?.interview_set?.manager_interview?.status !== 'submitted' ||
                interview?.interview_set?.crossed_interview?.status !== 'not_available_yet'"
              :to="{
                name: 'interview_answer',
                params: { id: interview?.interview_set?.manager_interview?.status !== 'submitted' ? interview?.interview_set?.manager_interview?.id : interview?.interview_set?.crossed_interview?.id },
              }"
              prepend-icon="custom:mingcute:arrow-right-line"
              variant="flat"
              :text="$t(getInterviewButtonText(
                interview?.interview_set?.employee_interview?.status,
                interview?.interview_set?.manager_interview?.status,
                interview?.interview_set?.crossed_interview?.status
              ))"
            />
          </div>

          <!-- Button Both -->
          <div
            v-else-if="interview?.interview_set?.manager_interview !== null && interview?.interview_set?.employee_interview !== null && interview?.interview_set?.crossed_interview === null"
            class="flex flex-col gap-3 lg:!flex-row lg:!self-end"
          >
            <svn-pro-button
              :to="{
                name: 'interview_answer',
                params: { id: interview?.interview_set?.employee_interview?.id },
              }"
              :disabled="interview?.interview_set?.employee_interview.status !== 'submitted'"
              variant="outlined"
              :text="$t(`View interviewee's answers`)"
            />

            <svn-pro-button
              :to="{
                name: 'interview_answer',
                params: { id: interview?.interview_set?.manager_interview?.id },
              }"
              :prepend-icon="interview?.interview_set?.crossed_interview?.status === 'submitted' || interview?.interview_set?.manager_interview?.status === 'in_progress' ? 'custom:mingcute:arrow-right-line' : ''"
              :variant="interview?.interview_set?.crossed_interview?.status === 'submitted' || interview?.interview_set?.manager_interview?.status === 'in_progress' ? 'flat' : 'outlined'"
              :text="$t(getInterviewButtonText(
                interview?.interview_set?.employee_interview?.status,
                interview?.interview_set?.manager_interview?.status,
                interview?.interview_set?.crossed_interview?.status
              ))"
            />
          </div>

          <!-- Button IR -->
          <div
            v-else-if="interview?.interview_set?.employee_interview === null"
            class="flex flex-col gap-3 lg:!flex-row lg:!self-end"
          >
            <svn-pro-button
              :prepend-icon="interview?.interview_set?.manager_interview?.status !== 'submitted' ? 'custom:mingcute:arrow-right-line' : ''"
              :to="{
                name: 'interview_answer',
                params: { id: interview?.interview_set?.manager_interview?.id },
              }"
              :variant="interview?.interview_set?.manager_interview?.status !== 'submitted' ? 'flat' : 'outlined'"
              :text="$t(getInterviewButtonText(
                interview?.interview_set?.employee_interview?.status,
                interview?.interview_set?.manager_interview?.status,
                interview?.interview_set?.crossed_interview?.status
              ))"
            />
          </div>

          <!-- Button IE -->
          <div
            v-else-if="interview?.interview_set?.employee_interview && !interview?.interview_set?.manager_interview && !interview?.interview_set?.crossed_interview && interview?.interview_set?.employee_interview?.status === 'submitted'"
            class="flex flex-col gap-3 lg:!flex-row lg:!self-end"
          >
            <svn-pro-button
              v-if="interview?.interview_set?.employee_interview?.status"
              :to="{
                name: 'interview_answer',
                params: { id: interview?.interview_set?.employee_interview?.id },
              }"
              variant="outlined"
              :text="$t(`View interviewee's answers`)"
            />
          </div>
        </div>

        <v-divider
          v-if="index !== campaignInterviews?.length - 1"
          class="border-opacity-100 border-[#767680]"
        />
      </div>
    </template>
  </div>

  <svn-pro-dialog-validation
    ref="invitationRef"
    :max-width=" 480"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Send')"
    :title="$t(`Participant will receive an invitation`)"
    :content-text="$t('An email will be sent to the participant with a link to this interview.')"
    @click-primary-button="sendInvitationEmail('invite', selectedInterviewId)"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </svn-pro-dialog-validation>

  <svn-pro-dialog-validation
    ref="reminderRef"
    :max-width=" 480"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Send')"
    :title="$t(`Participant will receive a reminder`)"
    :content-text="$t('An email will be sent to the participant with a link to this interview.')"
    @click-primary-button="sendInvitationEmail('reminder', selectedInterviewId)"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </svn-pro-dialog-validation>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { useCampaignStore } from "@/store/campaign.js";
import InterviewStatusChip from "../InterviewStatusChip.vue";
import { useMyTeamInterviewsStore } from "@/store/my-team-interviews.js";
import { InterviewOneToOneLabels, InterviewStatuses } from '@/constants/types';
import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
import PopUpSetAnotherInterviewer from "../../popUpComponents/PopUpSetAnotherInterviewer.vue";
import PopUpShiftCrossInterviewDate from "../../popUpComponents/PopUpShiftCrossInterviewDate.vue";

const props = defineProps({
  campaignId: { type: Number, required: true },
  campaignTitle: { type: String, required: true },
  campaignDeadline: { type: String, required: true },
  campaignType: { type: String, required: true },
  campaignInterviews: { type: Array, required: true },
})

const emit = defineEmits(['update-card'])

const { isMdScreen } = storeToRefs(useMobileStore());

const snackbar = useSnackbar();
const { fetchCampaign } = useCampaignStore();
const { resetStates, fetchMyTeamInterviews, fetchMyTeamInterviewsToDo, fetchMyTeamInterviewsDone } = useMyTeamInterviewsStore();

const reminderRef = ref(null);
const invitationRef = ref(null);
const activatorMobile = ref(false);
const activatorDesktop = ref(false);
const selectedInterviewId = ref(null);

const getInterviewButtonText = (employee, manager, crossed) => {
  if (manager === 'not_started') {
    return "Start my interview"
  }
  else if (manager === 'in_progress') {
    return "Continue my interview"
  }
  else if (employee === 'submitted' && manager === 'submitted' && crossed === 'not_started') {
    return "Start Cross Review"
  }
  else if (employee === 'submitted' && manager === 'submitted' && crossed === 'in_progress') {
    return "Continue Cross Review"
  }
  else if (employee === 'submitted' && manager === 'submitted' && crossed === 'submitted') {
    return "View Cross Review answers"
  }
  else if (manager === 'submitted') {
    return "View my answers"
  }
  else {
    return ""
  }
}

const sendInvitation = (id) => {
  invitationRef.value.dialogRef.dialog = true
  selectedInterviewId.value = id
}

const sendReminder = () => {
  reminderRef.value.dialogRef.dialog = true
  selectedInterviewId.value = id
}

const sendInvitationEmail = async (emailType, employeeId) => {
  try {
    await axiosService.get(`/api/v2/interview_app/campaigns/${props.campaignId}/send_notification_email`,
    {
      params: {
        email_type: emailType,
        user_id: employeeId,
      },
    });

    if (isMdScreen?.value) {
      document.getElementById('activatorDesktop').click();
      // activatorDesktop.value.click()
    } else {
      document.getElementById('activatorDesktop').click();
      // activatorMobile.value.click()
    }
    snackbar.setMultiLine(true).setMsg(emailType === 'invite' ? "Invitation sent. The employee will receive an email in a few moments." : "Reminder sent. The employee will receive an email in a few moments.").setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]').setBgColor("onSurface").displaySnackBar();
  } catch (error) {
    snackbar.setMsg("Error sending email.").setCustomClass('mb-4').setBgColor("onSurface").displaySnackBar();
  }
};

const callback = () => {
  if (isMdScreen?.value) {
    activatorDesktop?.value?.click()
  } else {
    activatorMobile?.value?.click()
  }
  resetStates()
  fetchMyTeamInterviews()
  fetchMyTeamInterviewsToDo()
  fetchMyTeamInterviewsDone()
}
</script>
