<template>
  <nav class="w-full !h-[64px] flex gap-4 sticky top-0 z-50 border-b border-b-[#767680] border-opacity-100 bg-surfaceBright px-4 py-2">
    <div class="w-full flex flex-1 items-center justify-start gap-2">
      <router-link
        class="hidden sm:flex items-center justify-center h-[48px] w-[48px]"
        to="/"
      >
        <svn-pro-icon-button
          :icon="route.name === 'home' ? 'custom:mingcute:home-4-fill' : 'custom:mingcute:home-4-line'"
          color="onSurfaceVariant"
          variant="text"
        />
      </router-link>

      <svn-pro-divider
        v-if="route?.name !== 'home'"
        vertical class="hidden sm:flex border-[#767680]"
      />

      <div class="sm:hidden">
        <svn-pro-icon-button
          icon="mdi-menu"
          variant="text"
          color="default"
          @click.stop="drawer = !drawer"
        />
      </div>

      <div
        v-if="appTitle"
        class="hidden xl:flex items-center"
      >
        <div class="flex gap-2 items-center pl-2">
          <div>
            <Icon
              :icon="appIcon"
              width="24"
              height="24"
              class="text-primary"
            />
          </div>

          <svn-pro-title h6 regular>
            {{ $t(appTitle) }}
          </svn-pro-title>

          <div class="flex justify-center gap-2 items-start">
            <router-link
              v-for="(link, idx) in links"
              :key="idx"
              :to="{ name: link.linkName }"
            >
              <svn-pro-button
                variant="text"
                :color="route?.name === link.linkName ? 'primary' : 'default'"
                class="capitalize"
                :text="$t(link.title)"
              />
            </router-link>
          </div>
        </div>
      </div>

      <!-- Taille intermédiaire -->
      <div
        v-if="appTitle"
        class="xl:hidden xs:hidden sm:flex items-center gap-2"
      >
        <div class="flex gap-2 items-center pl-2">
          <div>
            <Icon
              :icon="appIcon"
              width="24"
              height="24"
              class="text-primary"
            />
          </div>

          <svn-pro-title h6 regular>
            {{ $t(appTitle) }}
          </svn-pro-title>

          <svn-pro-menu>
            <template #activator="{ isActive }">
              <svn-pro-button
                variant="text"
                :color="route?.name === currentRouteLinkName() ? 'primary' : 'default'"
                class="capitalize"
                :append-icon="(isActive && links?.length) ? 'custom:mingcute:up-small-line' : 'custom:mingcute:down-small-line'"
                :text="$t(currentPageName())"
              />
            </template>

            <template #dropdown>
              <v-list-item
                v-for="(item, i) in currentAppPages"
                :key="i"
                :to="{
                  name: item.linkName,
                  params: item.linkName === 'people_show' ? { id: id } : null,
                  query: item.linkName === 'people_show' ? { page: 'interviews' } : null
                }"
                :value="item"
                @click="mobilMenuDialog = false"
              >
                <svn-pro-text body-large regular>
                  {{ $t(item.title) }}
                </svn-pro-text>
              </v-list-item>
            </template>
          </svn-pro-menu>
        </div>
      </div>
    </div>

    <app-and-user-menus
      :id="id"
      :firstname="firstname"
      :lastname="lastname"
      :email="email"
      :avatar="avatar"
      :access-level="accessLevel"
      :impersonate-display="impersonateDisplay"
      :logo="logo?.['150']"
      :name="name"
      :applications-filtered="applicationsFiltered"
      v-bind="$attrs"
      @display-impersonate="displayImpersonate"
    />
  </nav>

  <slot />

  <!-- Drawer -->
  <v-navigation-drawer
    v-model="drawer"
    temporary
    :width="320"
    class="!rounded-r-[12px]"
  >
    <!-- Menu List -->
    <div class="h-full flex flex-col p-3">
      <div class="w-full h-14 flex justify-between items-center pl-4 pr-2 py-2">
        <svn-pro-text body-medium medium color="onSurfaceVariant">
          {{ appClicked ? getClickedAppLabel : $t('Aleph') }}
        </svn-pro-text>

        <svn-pro-icon-button
          v-if="!appClicked"
          variant="text"
          class="text-onSurfaceVariant"
          icon="custom:mingcute:close-line"
          @click="drawer = !drawer"
        />
      </div>

      <v-list>
        <div v-if="appClicked">
          <v-list-item :active="false" value="back_to_menu" @click="appClicked = null">
            <div class="flex gap-4 items-center">
              <div>
                <Icon
                  icon="mingcute:arrow-left-line"
                  height="24"
                  width="24"
                  class="text-onSurfaceVariant"
                />
              </div>

              <svn-pro-text body-large regular class="flex-1">
                {{ $t('App menu') }}
              </svn-pro-text>
            </div>
          </v-list-item>

          <v-list-item
            v-for="(item, i) in subMenuMobileItems"
            :key="i"
            :active="route?.name === item?.linkName"
            :to="item?.route"
            :value="item?.linkName"
          >
            <svn-pro-text body-large regular class="flex-1">
              {{ item?.title }}
            </svn-pro-text>
          </v-list-item>
        </div>

        <div v-else>
          <v-list-item
            v-for="(item, i) in mobileMenuListFiltered"
            :key="i"
            :to="item?.to"
            :value="item?.linkName"
            :class="item?.linkName === 'my_interviews' ? 'border-opacity-100 border-t border-borderColor' : ''"
            @click="item?.onClick()"
          >
            <div class="flex gap-4 items-center">
              <div>
                <Icon
                  :icon="item?.icon"
                  height="24"
                  width="24"
                  class="text-onSurfaceVariant"
                />
              </div>

              <svn-pro-text body-large regular class="flex-1">
                {{ item?.title }}
              </svn-pro-text>

              <div v-if="item?.linkName !== 'home' && item?.linkName !== 'company' && item?.linkName !== 'people_index'">
                <Icon
                  icon="mingcute:arrow-right-line"
                  height="24"
                  width="24"
                  class="text-onSurfaceVariant"
                />
              </div>
            </div>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useUserStore } from "@/store/user.js";
import { useRoute, useRouter } from "vue-router";
import { Icon } from "@iconify/vue/dist/iconify.js";
import { useCompanyStore } from "@/store/company.js";
import homeIcon from "@/assets/images/icons/home.svg";
import peopleIcon from "@/assets/images/icons/people.svg";
import companyIcon from "@/assets/images/icons/company.svg";
import roadmapIcon from "@/assets/images/icons/roadmaps.svg";
import { useImpersonateStore } from "@/store/impersonate.js";
import trainingIcon from "@/assets/images/icons/trainings.svg";
import interviewIcon from "@/assets/images/icons/interviews.svg";
import AppAndUserMenus from "@/components/header/AppAndUserMenus.vue";

const { impersonateDisplay } = storeToRefs(useImpersonateStore());
const { logo, applications, name } = storeToRefs(useCompanyStore());
const { id, firstname, lastname, email, avatar, accessLevel } = storeToRefs(useUserStore());

const { displayImpersonate } = useImpersonateStore();
const { isAdminOrAbove, isManagerCreatorOrAbove, isManagerOrAbove, isManagerCreatorOrBelow } = useUserStore();

const route = useRoute();
const router = useRouter();

const drawer = ref(false);
const appClicked = ref(null);
const mobilMenuDialog = ref(false);

const props = defineProps({
  appIcon: { type: String, default: null },
  appTitle: { type: String, default: null },
  links: { type: Array, default: () => [] },
});

const mobileMenuList = computed(() => {
  let items = []
  let home = {
    title: "Home",
    linkName: "home",
    icon: "mingcute:home-4-line",
    color: "#333333",
    onClick: () => appClicked.value = null,
    to: {
      name: 'home',
      query: null,
      params: null,
    },
  }
  let company = {
    title: "Company",
    linkName: "company",
    icon: "mingcute:building-2-line",
    color: "#333333",
    onClick: () => appClicked.value = null,
    to: {
      name: 'company',
      query: { tab: 'information' },
      params: null,
    },
  }
  let apps = [
    {
      title: "Interview",
      linkName: "my_interviews",
      icon: "mingcute:chat-3-line",
      color: "#3177B7",
      onClick: () => appClicked.value = 'my_interviews',
      to: null,
      // to: {
      //   name: 'my_interviews',
      //   query: null,
      //   params: null,
      // },
    },
    {
      title: "Learn",
      linkName: "my_learnings",
      icon: "mingcute:mortarboard-line",
      color: "#EF8C64",
      onClick: () => appClicked.value = 'my_learnings',
      to: null,
      // to: {
      //   name: 'my_learnings',
      //   query: null,
      //   params: null,
      // },
    },
    {
      title: "Roadmap",
      linkName: "my_roadmaps",
      icon: "mingcute:target-line",
      color: "#5C95FF",
      onClick: () => appClicked.value = 'my_roadmaps',
      to: null,
      // to: {
      //   name: 'my_roadmaps',
      //   query: null,
      //   params: null,
      // },
    },
  ]
  let people = {
    title: "People",
    linkName: "people_index",
    icon: "mingcute:group-3-line",
    color: "#333333",
    onClick: () => appClicked.value = null,
    to: {
      name: 'people_index',
      query: null,
      params: null,
    },
  }

  items?.push(home)
  if (isAdminOrAbove()) {
    items?.push(company)
  }
  items?.push(...apps)
  if (isAdminOrAbove()) {
    items?.push(people)
  }

  return items
})

const getClickedAppLabel = computed(() => {
  switch (appClicked?.value) {
    case 'my_interviews':
      return 'Interview'
    case 'my_learnings':
      return 'Learn'
    case 'my_roadmaps':
      return 'Roadmap'
    case 'default':
      return ''
  }
})

const subMenuMobileItems = computed(() => {
  switch (appClicked?.value) {
    case 'my_interviews':
      let itemsInterview = []
      let itemsInterviewGlobal = [
        {
          title: "My Interviews",
          linkName: "my_interviews",
          route: "/v2/interviews/my_interviews",
        },
      ]
      let itemsInterviewManagerOrAbove = [
        {
          title: "My Team Interviews",
          linkName: "my_team_interviews",
          route: "/v2/interviews/my_team_interviews",
        },
      ]
      let itemsInterviewManagerCreatorAndAbove = [
        {
          title: "Campaigns",
          linkName: "campaigns",
          route: "/v2/interviews/campaigns",
        },
        {
          title: "Templates",
          linkName: "interview_forms",
          route: "/v2/interviews/templates/index",
        },
        {
          title: "Reports",
          linkName: "reports",
          route: "/v2/interviews/reports",
        },
      ]

      itemsInterview?.push(...itemsInterviewGlobal)
      if (isManagerOrAbove()) {
        itemsInterview?.push(...itemsInterviewManagerOrAbove)
      }
      if (isManagerCreatorOrAbove()) {
        itemsInterview?.push(...itemsInterviewManagerCreatorAndAbove)
      }
      return itemsInterview
    case 'my_learnings':
      let itemsLearn = []
      let itemsLearnGlobal = [
        {
          title: "My Trainings",
          linkName: "my_learnings",
          route: "/learns/my_learnings",
        },
        {
          title: "Catalog",
          linkName: "catalog",
          route: "/learns/catalog",
        },
      ]
      let itemsLearnManagerCreatorAndAbove = [
        {
          title: "Trainings",
          linkName: "trainings",
          route: "/learns/trainings",
        },
      ]

      itemsLearn?.push(...itemsLearnGlobal)
      if (isManagerCreatorOrAbove()) {
        itemsLearn?.push(...itemsLearnManagerCreatorAndAbove)
      }
      return itemsLearn
    case 'my_roadmaps':
      let itemsRoadmap = []
      let itemsRoadmapGlobal = [
        {
          title: "My Roadmap",
          linkName: "my_roadmaps",
          route: "/roadmaps/my_roadmaps",
        },
      ]
      let itemsRoadmapManagerOrAbove = [
        {
          title: "My Team Roadmaps",
          linkName: "my_team_roadmaps",
          route: "/roadmaps/my_team_roadmaps",
        },
      ]
      let itemsRoadmapManagerCreatorAndAbove = [
        {
          title: "Targets",
          linkName: "roadmaps_targets",
          route: "/roadmaps/targets/index",
        },
        // {
        //   title: "All Roadmaps",
        //   linkName: "roadmaps_employees",
        //   route: roadmapIcon,
        // },
        {
          title: "Templates",
          linkName: "roadmaps_templates",
          route: "/roadmaps/templates/index",
        },
      ]

      itemsRoadmap?.push(...itemsRoadmapGlobal)
      if (isManagerOrAbove()) {
        itemsRoadmap?.push(...itemsRoadmapManagerOrAbove)
      }
      if (isManagerCreatorOrAbove()) {
        itemsRoadmap?.push(...itemsRoadmapManagerCreatorAndAbove)
      }
      return itemsRoadmap
    case 'default':
      return []
  }
})

const middleScreenMenuList = {
  Home: [
    {
      title: "Home",
      linkName: "home",
      icon: homeIcon,
    },
  ],
  Interview: [
    {
      title: "My interviews",
      linkName: "my_interviews",
      icon: interviewIcon,
    },
    {
      title: "My Team Interviews",
      linkName: "my_team_interviews",
      icon: interviewIcon,
    },
    {
      title: "Campaigns",
      linkName: "campaigns",
      icon: interviewIcon,
    },
    {
      title: "Templates",
      linkName: "interview_forms",
      icon: interviewIcon,
    },
    {
      title: "Reports",
      linkName: "reports",
      icon: interviewIcon,
    }
  ],
  Learn: [
    {
      title: "My Trainings",
      linkName: "my_learnings",
      icon: trainingIcon,
    },
    {
      title: "Catalog",
      linkName: "catalog",
      icon: trainingIcon,
    },
    {
      title: "Trainings",
      linkName: "trainings",
      icon: trainingIcon,
    },
  ],
  Roadmap: [
    {
      title: "My Roadmap",
      linkName: "my_roadmaps",
      icon: roadmapIcon,
    },
    {
      title: "My Team Roadmaps",
      linkName: "my_team_roadmaps",
      icon: roadmapIcon,
    },
    {
      title: "Targets",
      linkName: "roadmaps_targets",
      icon: roadmapIcon,
    },
    // {
    //   title: "People targets",
    //   linkName: "roadmaps_employees",
    //   icon: roadmapIcon,
    // },
    {
      title: "Target templates",
      linkName: "roadmaps_templates",
      icon: roadmapIcon,
    }
  ],
  Company: [
    {
      title: "Company",
      linkName: "company",
      icon: companyIcon,
    }
  ],
  People: [
    {
      title: "People",
      linkName: "people_index",
      icon: peopleIcon,
    },
    {
      title: "Current",
      linkName: "people_show",
      icon: peopleIcon,
    }
  ]
}

const middleScreenMenuListForManager = {
  Home: [
    {
      title: "Home",
      linkName: "home",
      icon: homeIcon,
    },
  ],
  Interview: [
    {
      title: "My interviews",
      linkName: "my_interviews",
      icon: interviewIcon,
    },
    {
      title: "My Team Interviews",
      linkName: "my_team_interviews",
      icon: interviewIcon,
    }
  ],
  Learn: [
    {
      title: "My learn - coming soon",
      linkName: "my_learnings",
      icon: trainingIcon,
    },
    {
      title: "Catalog",
      linkName: "catalog",
      icon: trainingIcon,
    }
  ],
  Roadmap: [
    {
      title: "My Roadmap",
      linkName: "my_roadmaps",
      icon: roadmapIcon,
    },
    {
      title: "My Team Roadmaps",
      linkName: "my_team_roadmaps",
      icon: roadmapIcon,
    },
  ],
  People: [
    {
      title: "Current",
      linkName: "people_show",
      icon: peopleIcon,
    }
  ]
}

const middleScreenMenuListForEmployee = {
  Home: [
    {
      title: "Home",
      linkName: "home",
      icon: homeIcon,
    },
  ],
  Interview: [
    {
      title: "My interviews",
      linkName: "my_interviews",
      icon: interviewIcon,
    }
  ],
  Learn: [
    {
      title: "My learn - coming soon",
      linkName: "my_learnings",
      icon: trainingIcon,
    },
    {
      title: "Catalog",
      linkName: "catalog",
      icon: trainingIcon,
    }
  ],
  Roadmap: [
    {
      title: "My Roadmap",
      linkName: "my_roadmaps",
      icon: roadmapIcon,
    }
  ],
  People: [
    {
      title: "Current",
      linkName: "people_show",
      icon: peopleIcon,
    }
  ]
}

let appsConfigs = [
  {
    backendNameToMatch: "interviews",
    appName: "Interview",
    linkName: "my_interviews",
    icon: 'mingcute:chat-3-line',
    iconColor: '#3177B7',
  },
  {
    backendNameToMatch: "trainings",
    appName: "Learn",
    linkName: "my_learnings",
    icon: 'mingcute:mortarboard-line',
    iconColor: '#EF8C64',
    beta: true,
  },
  {
    backendNameToMatch: "objectives",
    appName: "Roadmap",
    linkName: "my_roadmaps",
    icon: 'mingcute:target-line',
    iconColor: '#5C95FF',
  },
  {
    backendNameToMatch: null,
    appName: "People",
    linkName: "people_index",
    icon: 'mingcute:group-3-line',
    iconColor: '#333333'
  }
];

const applicationsFiltered = computed(() => {
  appsConfigs = appsConfigs.filter(
    (app) =>
      applications.value.includes(app.backendNameToMatch) ||
      (app.appName === "People" && isAdminOrAbove())
  );

  return appsConfigs
});

const mobileMenuListFiltered = computed(() => {
  return mobileMenuList?.value?.filter(item => !(item.linkName === 'people_index' && isManagerCreatorOrBelow()))
})

const currentPageName = () => {
  const routeName = currentAppPages?.value?.find((link) => link.linkName === router.currentRoute?._value?.name)
  return routeName === undefined ?  props.appTitle : routeName.title
}

const currentRouteLinkName = () => {
  return currentAppPages?.value?.find((link) => link.linkName === route.name)?.linkName
}

const currentAppPages = computed(() => {
  return filterCurrentAppPages.value[props.appTitle]
});

const filterCurrentAppPages = computed(() => {
  return isManagerCreatorOrAbove() ? middleScreenMenuList : (isManagerOrAbove() ?  middleScreenMenuListForManager : middleScreenMenuListForEmployee)
});

const deduceSubLinkDisplay = (item) => {
  const employeePages = [
    'my_interviews',
    'my_learnings',
    'catalog',
    'my_roadmaps'
  ]
  const managerPages = [
    'my_team_interviews',
    'my_team_roadmaps'
  ]
  const managerCreatorPage = [
    'trainings',
    'campaigns',
    'interview_forms',
    'reports',
    'roadmaps_targets',
    'roadmaps_employees',
    'roadmaps_templates'
  ]

  if (employeePages.includes(item.linkName))
    return true
  else if (managerPages.includes(item.linkName) &&
      isManagerOrAbove())
    return true
  else if (managerCreatorPage.includes(item.linkName) && isManagerCreatorOrAbove())
    return true
  else return false
}
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
