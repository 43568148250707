<template>
  <svn-pro-form-block
    :id="question?.id"
    :question-type="question?.type"
    :has-title="question?.title?.length ? true : false"
    :title="`${question?.title} ${
      question?.required_for === interviewKind ||
        question?.required_for === 'all' ? '*' : ''
    }`"
    :type="getFormBlockType(question?.type)"
    :size="isMdScreen ? ComponentSizes?.default : ComponentSizes?.compact"
    :has-divider="question?.type === InterviewAppQuestionTypes?.chapter"
    :has-description="question?.description?.length ? true : false"
    :description="question?.description"
    :has-variable-content="question?.type !== InterviewAppQuestionTypes?.chapter && question?.type !== InterviewAppQuestionTypes?.paragraph"
    :has-editable-area="false"
  >
    <template #variable-content>
      <div :id="question?.id" class="w-full flex flex-col items-start gap-6 self-stretch">
        <div
          v-for="(answer, index) in question?.answers"
          :key="index"
          class="w-full"
        >
          <svn-pro-answer-bubble-interview
            :user="answer?.user"
            :rating-length="`${question?.options}`"
            :question-type="question?.type"
            :bubble-title="`${answer?.user?.firstname} ${answer?.user?.lastname}`"
            :selected-value="answer?.value"
            :comment="question?.type === InterviewAppQuestionTypes?.open ? answer?.value : answer?.comments"
          />
        </div>
      </div>
    </template>
  </svn-pro-form-block>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useInterviewStore } from "@/store/interview.js";
import { FormBlockTypes, ComponentSizes, InterviewAppQuestionTypes } from 'svn-ui-library/components'

const { isMdScreen } = storeToRefs(useMobileStore());
const { interviewKind } = storeToRefs(useInterviewStore());

const props = defineProps(["question", "loopIdx"]);

const getFormBlockType = (questionType) => {
  if ([InterviewAppQuestionTypes?.chapter, InterviewAppQuestionTypes?.paragraph, InterviewAppQuestionTypes?.open]?.includes(questionType)) {
    return FormBlockTypes?.text_area
  } else if (questionType === InterviewAppQuestionTypes?.rating) {
    return FormBlockTypes?.icon_buttons
  } else if (questionType === InterviewAppQuestionTypes?.mcq) {
    return FormBlockTypes?.radio_buttons
  } else {
    return FormBlockTypes?.text_area
  }
}
</script>