<template>
  <v-window-item value="module">
    <v-fade-transition>
      <!-- Skeleton loaders -->
      <div
        v-if="loading"
        class="grid grid-cols-1 my-6 xl:!mx-0 gap-4 justify-center items-start self-stretch
          sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <skeleton-loader-module-card
          v-for="item in 8"
          :key="item"
        />
      </div>

      <!-- Finished loading -->
      <div
        v-else
        class="mx-6 md:!mx-0"
      >
        <!-- Search & Select -->
        <div class="w-full flex gap-4 my-4 flex-col md:!flex-row justify-start items-center">
          <!-- Search -->
          <svn-pro-text-field
            v-model="searchTermModules"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @update:model-value="search"
            class="w-full"
            clearable
            clear-icon="custom:mingcute:close-line"
          />

          <!-- Sort & Filter -->
          <div class="w-full flex justify-center items-center gap-4">
            <svn-pro-select
              v-model="selectFilterModules"
              :label="$t('Sort by')"
              class="w-full"
              :items="selectFilterList"
              item-title="display"
              item-value="value"
              @update:model-value="search"
            />

            <!-- Filter -->
            <svn-pro-menu menuClass="p-0">
              <template #activator="{ props }">
                <svn-pro-button
                  v-bind="props"
                  :active="selectedThemesListModules?.length > 0"
                  :text="`${$t('Filter')} ${selectedThemesListModules?.length ? `(${selectedThemesListModules?.length})` : ''}`"
                  prepend-icon="custom:mingcute:filter-2-line"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <v-list-item :active="false" class="!p-0" @click.stop> <!-- Prevent click propagation -->
                  <svn-pro-combobox
                    v-model="selectedThemesListModules"
                    :items="themes"
                    item-title="name"
                    :label="$t('Themes')"
                    class="!w-[210px] p-4"
                    :clearable="false"
                    multiple
                    return-object
                    @update:model-value="search"
                  />
                </v-list-item>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Filter results -->
        <div
          v-if="selectedThemesListModules?.length > 0"
          class="flex justify-between items-start self-stretch pt-4 xl:!px-0 xl:flex-wrap"
        >
          <!-- Themes list -->
          <div
          v-if="selectedThemesListModules?.length"
            class="flex flex-wrap gap-2"
          >
            <svn-pro-chip
              v-for="theme in selectedThemesListModules"
              key="entityTag.id"
              class=""
              :text="theme.name"
              is-slot-append="true"
            >
              <template #append>
                <Icon
                  icon="mingcute:close-line"
                  width="18"
                  height="18"
                  class="ml-2 cursor-pointer"
                  @click="toggleThemeSelection(theme)"
                />
              </template>
            </svn-pro-chip>
          </div>

          <!-- Clear all themes selected -->
          <svn-pro-button
            v-if="selectedThemesListModules?.length > 1"
            @click="clearAllSelectedThemes"
            variant="text"
            :text="$t('Clear all')"
          />
        </div>

        <!-- Modules card list -->
        <v-infinite-scroll
          :items="learnModules"
          class="overflow-hidden w-full"
          color="primary"
          @load="loadMoreData"
        >
          <div class="flex flex-wrap md:!grid md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-4 w-full gap-4 justify-center md:!justify-start items-center">
            <module-card
              v-for="(module) in learnModules"
              :learnModule="module"
              :id="module?.id"
              :key="module?.id"
              :module-creator-id="module?.creator_id"
              :background-image="module?.cover_url?.['500']"
              :title="module?.title"
              :reactions="module?.user_reactions"
              :is-favorite="module?.connected_user_favorited"
              :theme-list="module?.themes?.map(theme => theme?.name)"
              editable
              :duration="module?.duration"
              :submission-status="module?.submission_status"
              @toggle-favorite="toggleModuleFavorite(module)"
              @go-to-item-show="goToModuleShowEdit(module.id)"
              @duplicate-module="toggleDuplicateModule(module?.id)"
              @add-to-playlist="toggleAddToPlaylists(module?.id)"
              @delete-module="deleteLearnModule(module?.id)"
            />
          </div>

          <template #empty />
        </v-infinite-scroll>

        <div
          class="flex text-center justify-center items-center"
          v-if="!learnModules?.length"
        >
          <svn-pro-empty-states
            :actions="isManagerCreatorOrAbove()"
            :variant="searchTermModules ? 'results' : 'index'"
            :title="$t('No module yet')"
            :size="isMdScreen ? 'default' : 'compact'"
            :action-primary-title="$t('New Module')"
            @click-primary="$emit('goToNewModule')"
            prepend-primary="custom:mingcute:add-line"
            :supporting-text="emptyStateSupportingText"
          />
        </div>
      </div>
    </v-fade-transition>

    <!-- Dialog duplicate module -->
    <dialog-duplicate-module
      ref="duplicateModuleDialog"
      @duplicate-module="duplicateLearnModule"
    />

    <!-- Dialog Add Module To Playlist(s) -->
    <dialog-add-module-to-playlists
      v-if="learnPlaylists"
      ref="addModuleToPlaylistsDialog"
      :module-id="idModuleToAddToPlaylists"
      @close-dialog="closeDialog"
    />
  </v-window-item>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { storeToRefs } from "pinia";
import { ref, onMounted, computed } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { useLearnThemesStore } from '@/store/learn-themes';
import { useLearnModuleStore } from "@/store/learn-module.js";
import { useLearnPlaylistStore } from "@/store/learn-playlist.js";
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import filters from '@/tools/filters';
import DialogDuplicateModule from '@/components/BktPopUp/Dialogs/learn/DialogDuplicateModule.vue';
import DialogAddModuleToPlaylists from '@/components/BktPopUp/Dialogs/learn/DialogAddModuleToPlaylists.vue';
import ModuleCard from "@/components/trainingApp/ModuleCard.vue";
import router from '@/router';
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import SkeletonLoaderModuleCard from '@/components/skeletonLoaders/SkeletonLoaderModuleCard.vue';
import { useUserStore } from "@/store/user.js";

const props = defineProps({
  themes: { type: Array, default: null },
})

const { isManagerCreatorOrAbove } = useUserStore();

onMounted(async() => {
  try {
    await fetchModules().then((res) => {
      totalPages.value = res.data.meta.pagination.total_pages
      loading.value = false
      learnModules.value = res.data.learn_modules
    })
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
})

const page = ref(1)

const totalPages = ref(null)

const loading = ref(true)

const selectFilterList = ref([
  {
    display: i18n.global.t("Decreasing publication date"),
    value: "desc-publication",
  },
  {
    display: i18n.global.t("Increasing publication date"),
    value: "asc-publication",
  },
  {
    display: i18n.global.t("Increasing acquisition date"),
    value: "asc-acquisition",
  },
  {
    display: i18n.global.t("Decreasing acquisition date"),
    value: "desc-acquisition",
  },
])

const selectFilterModules = ref(selectFilterList?.value[0].value)

const idModuleToDelete = ref(null)

const moduleToDeleteTraining = ref(null)

const idModuleToDuplicate = ref(null)

const idModuleToAddToPlaylists = ref(null)

const searchTermModules = ref('')

const duplicateModuleDialog = ref(false)

const addModuleToPlaylistsDialog = ref(false)

const filteredThemeSearchModules = ref('')

const selectedThemesListModules = ref([])

const filterByFavorites = ref(false)

const snackbar = useSnackbar()

const breadcrumbsStore = useBreadcrumbsStore();

const { addNode, update } = breadcrumbsStore

const { fetchThemes } = useLearnThemesStore()

const { fetchModules, fetchModulesOnlyWithPages, duplicateModule,
  toggleFavorite, deleteModule, fetchTrashModules, restoreModule, deleteModulePermanently } = useLearnModuleStore();

const { isMobile, isMdScreen } = storeToRefs(useMobileStore())

const { learnModules, learnTrashModules } = storeToRefs(useLearnModuleStore());
const { learnPlaylists } = storeToRefs(useLearnPlaylistStore());

const toggleDuplicateModule = (id) => {
  idModuleToDuplicate.value = id
  duplicateModuleDialog.value.dialogDuplicateModule = true
}

const duplicateLearnModule = async(title) => {
  try {
    const duplicated = await duplicateModule(idModuleToDuplicate.value, title)

    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Module duplicated.')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[72px]' : 'mb-16')

    snackbar.displaySnackBar()

    idModuleToDuplicate.value = null
    duplicateModuleDialog.value.dialogDuplicateModule = false

    setTimeout(() => {
      router.push({ name: 'module_edit', params: { id: duplicated.id } })
    }, 200);
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error duplicating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-16')
    snackbar.displaySnackBar()
  }
}


const toggleAddToPlaylists = (id) => {
  idModuleToAddToPlaylists.value = id
  addModuleToPlaylistsDialog.value.dialogAddToPlaylist = true
}

const closeDialog = () => {
  addModuleToPlaylistsDialog.value.dialogAddToPlaylist = false
}

const toggleThemeSelection = debounce(async(item) => {
  const found = selectedThemesListModules?.value?.find(theme => theme?.id === item?.id);

  if (found) {
    selectedThemesListModules?.value?.splice(selectedThemesListModules?.value?.findIndex((theme) =>
      theme?.id === item?.id), 1);
  }
  else {
    selectedThemesListModules?.value?.push(item);
  }
  search()
}, 200)

const toggleModuleFavorite = debounce(async(module) => {
  try {
    await toggleFavorite(module.id, module.status)

    if (filterByFavorites.value) {
      search()
    }
    snackbar.setBgColor('onSurface')
    if (module.connected_user_favorited) {
      snackbar.setMsg('Module added to favorites.')
    } else {
      snackbar.setMsg('Module removed from favorites.')
    }
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error toggling favorite')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()
  }
}, 200)

const toggleFavoritesSelection = debounce(() => {
  filterByFavorites.value = !filterByFavorites.value

  search()
}, 200)


const deleteLearnModule = async(id) => {
  try {
    await deleteModule(id)

    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Module has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()

    router.push({ name: "catalog" })
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error deleting module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()
  }
  idModuleToDelete.value = null
}

const goToModuleShowEdit = (id, title) => {
  // updateBreadcrumbs(title)
  router.push({ name: 'module_show', params: { id: id } })
}

const clearAllSelectedThemes = () => {
  selectedThemesListModules.value = [];
}

const searchThemes = async() => {
  try {
    await fetchThemes(filteredThemeSearchModules.value)
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error fetching themes')
    snackbar.displaySnackBar()
  }
}

const search = debounce(async() => {
  try {
    const res = await fetchModules(searchTermModules.value,
      selectedThemesListModules?.value?.map(theme => theme?.id), filterByFavorites.value, selectFilterModules.value)
    learnModules.value = res.data.learn_modules
    totalPages.value = res.data.meta.pagination.total_pages
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
}, 200)

const updateBreadcrumbs = (title) => {
  addNode(title, 'learns/catalog')
  update()
}

const loadMoreData = async({ done }) => {
  if (page.value < totalPages.value) {
    try {
      const res = await fetchModulesOnlyWithPages(page.value + 1, searchTermModules.value)

        if (res.data.learn_modules.length) {
          learnModules.value = [...learnModules.value, ...res.data.learn_modules]
          totalPages.value = res.data.meta.pagination.total_pages
        }
        done('ok')
        page.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const emptyStateSupportingText = computed(() => {
  return searchTermModules.value ? i18n.global.t('Oops, we didn’t find any results matching your search.') :
  (isManagerCreatorOrAbove() ? i18n.global.t(`There are no modules. Create your first modules!`) : i18n.global.t(`All modules availables will show up here.`))
})
</script>