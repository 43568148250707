<template>
  <div>
    <svn-pro-top-bar-learn-module
      :type="TopBarLearnModuleTypes.quiz"
      @close-and-go-back="
        router.push({
          name: 'module_show',
          params: { id: route.query.moduleId },
          query: {
            trainingId: route.query.trainingId,
          },
        })
      "
    ></svn-pro-top-bar-learn-module>

    <div
      class="flex flex-col justify-center items-center xs:mt-6 xs:mx-5 xs:mb-24 sm:mx-12 md:mx-auto md:max-w-[560px]"
    >
      <div class="mb-2">
        <svn-pro-title h4 medium>
          {{ $t('Quiz result') }}
        </svn-pro-title>
      </div>

      <div class="mb-8">
        <svn-pro-title class="text-center" h5 medium color="onSurfaceVariant">
          {{ route.query.moduleTitle }}
        </svn-pro-title>
      </div>

      <div v-if="submission" class="mb-4">
        <svn-pro-graph-target
          :progress="submission?.progressionToSvnProGraphTargetProgress()"
          :current-value="submission?.progressionNbr() + '%'"
          :indicator-type="SvnProGraphTargetIndicatorType.string"
        ></svn-pro-graph-target>
      </div>

      <div v-if="submission" class="mb-8">
        <svn-pro-text
          class="text-center"
          subtitleLarge
          medium
          color="onSurface"
        >
          {{ $t(submission?.getProgressionInfo(), { firstname: firstname }) }}
        </svn-pro-text>
      </div>

      <div class="mb-10" v-if="submission">
        <wrap-section-quiz-learn
          :quiz-id="parseInt(route.params.id)"
          :current-module-id="parseInt(route.query.moduleId)"
          :current-module-title="route.query.moduleTitle"
          :come-from-training="parseInt(route?.query?.trainingId)"
          :has-next-module="module?.pagination?.nextModuleId"
          :submission="submission"
          @go-to-module="goToModule"
          @go-to-training="goToTraining"
          @go-to-catalog="goToCatalog"
        ></wrap-section-quiz-learn>
      </div>

      <div class="mb-6 w-full">
        <svn-pro-title h6 medium color="onSurface">
          {{ $t('Answers Details') }}
        </svn-pro-title>
      </div>
      <div v-if="quiz != null && submission != null" class="space-y-6 w-full">
        <svn-pro-section-quiz-learn-base
          v-for="input in quiz.learnInputs.filter((item) =>
            item.isCheckBoxQuestion()
          )"
          :key="input.id"
          :correctionType="
            getSubmissionResponse(
              input.id
            )?.statusToSvnProSectionQuizLearnCorrectionType()
          "
          :list="input.proposals"
          :title="input.title"
        >
          <template #default="{ item, index }">
            <svn-pro-quiz-answer-learn-item
              :leftLetter="alphabetFromIndex(index)"
              :optionText="item.proposal"
              :link="false"
              :state="
                isProposalInSubmission(input.id, item.proposal)
                  ? getSubmissionResponse(
                      input.id
                    )?.statusToSvnProQuizAnswerLearnItemStates()
                  : SvnProQuizAnswerLearnItemStates.enable
              "
            ></svn-pro-quiz-answer-learn-item>
          </template>
        </svn-pro-section-quiz-learn-base>
      </div>
      <div v-else class="w-full space-y-4">
        <svn-pro-section-quiz-learn-skeleton />
        <svn-pro-section-quiz-learn-skeleton />
        <svn-pro-section-quiz-learn-skeleton />
      </div>
    </div>
    <!-- Reactions -->
    <modal-what-did-you-think-of-the-module
      ref="modalModuleReaction"
      :reactions="reactions"
      @update-reaction="onReactionUpdated"
      @click-primary-button="goToNextModuleOrCatalogOrCancel"
      @click-secondary-button="goToNextModuleOrCatalogOrCancel"
      @click-outside="goToNextModuleOrCatalogOrCancel"
      @click-close="goToNextModuleOrCatalogOrCancel"
    >
      <template #activator>
        <div class="hidden opacity-0" />
      </template>
    </modal-what-did-you-think-of-the-module>
  </div>
</template>

<script setup>
import {
  TopBarLearnModuleTypes,
  SvnProQuizAnswerLearnItemStates,
  SvnProSectionQuizLearnCorrectionType,
  SvnProCardSectionQuizResultLearnType,
  ComponentSizes,
  SvnProGraphTargetIndicatorType,
} from 'svn-ui-library/components';
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, computed } from 'vue';
import QuizApi from '../../../apis/quiz.api';
import QuizSubmissionApi from '@/apis/quiz_submission.api';
import ModuleApi from '../../../apis/module.api';
import WrapSectionQuizLearn from '@/views/learns/quiz/WrapSectionQuizLearn';
import useStringTools from '@/tools/useStringTools';
import { useUserStore } from '@/store/user';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar';
import ModalWhatDidYouThinkOfTheModule from '@/components/BktPopUp/Modals/learn/ModalWhatDidYouThinkOfTheModule';

import { storeToRefs } from 'pinia';
import i18n from '@/plugins/i18n.js';
const route = useRoute();
const router = useRouter();
const { alphabetFromIndex } = useStringTools();
import { reactionAPi } from '@/apis/reaction.api';
const { isMdScreen, isLgScreen } = storeToRefs(useMobileStore());

const submission = ref(null);
const quiz = ref(null);
const module = ref(null);
const routeName = ref(null);
const { firstname } = storeToRefs(useUserStore());
const modalModuleReaction = ref(null);
const snackbar = useSnackbar();

onMounted(async () => {
  if (!route.params.id) return router.back();

  try {
    quiz.value = await QuizApi.get(route.params.id);
  } catch (e) {
    console.log('quiz error', e);
  }

  try {
    submission.value = await QuizSubmissionApi.getSubmission(route.params.id);
  } catch (e) {
    console.log('submission not found', e);
  }

  if (route.query.trainingId) {
    // only paginate if we come from training
    try {
      module.value = await ModuleApi.paginateByTraining(
        route.query.moduleId,
        route.query.trainingId
      );
    } catch (e) {
      console.log('pagination by training error', e);
    }
  }
});

const goToCatalog = () => {
  modalModuleReaction.value.moduleReactionsModal.dialog = true;
  routeName.value = 'catalog';
};
const goToTraining = () => {
  modalModuleReaction.value.moduleReactionsModal.dialog = true;
  routeName.value = 'training_show';
};
const goToModule = () => {
  routeName.value = 'module_show';
  modalModuleReaction.value.moduleReactionsModal.dialog = true;
};

const onReactionUpdated = async (reactionType) => {
  try {
    const ret = await reactionAPi.put(route?.query?.moduleId, reactionType);
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error toggling reaction')
      .displaySnackBar();
  }
};
const isProposalInSubmission = (inputId, proposal) => {
  const idxInput = submission.value.learnResponsesCheckboxes.findIndex(
    (item) => item?.inputId === inputId
  );
  if (idxInput === -1) return;

  const idxProposal = submission.value.learnResponsesCheckboxes[
    idxInput
  ].selectedValues.findIndex((item) => item === proposal);

  if (idxProposal === -1) {
    return false;
  } else {
    return true;
  }
};

const getSubmissionResponse = (inputId) => {
  return submission.value?.learnResponsesCheckboxes.find(
    (item) => item.inputId === inputId
  );
};

const goToNextModuleOrCatalogOrCancel = () => {
  modalModuleReaction.value.moduleReactionsModal.dialog = false;
  if (routeName.value == 'module_show') {
    router.push({
      name: 'module_show',
      params: { id: module?.value?.pagination?.nextModuleId },
      query: {
        trainingId: route?.query?.trainingId,
      },
    });
  } else if (routeName.value == 'training_show') {
    router.push({
      name: 'training_show',
      params: { id: route?.query?.trainingId },
    });
  } else {
    router.push({ name: 'catalog' });
  }
};

const reactions = computed(() => {
  return [
    {
      value: 'like',
      icon: 'https://lottie.host/?file=1d730887-ff07-4bcb-916a-1c47a1fc2191/tBJxPeERxp.json',
      text: i18n.global.t('I like it'),
      active: module?.value?.userReactions?.find(
        (reaction) => reaction?.type === 'like'
      )?.selected,
    },
    {
      value: 'recommend',
      icon: 'https://lottie.host/?file=f503d535-88db-4e36-b0b3-a061d09424f7/XWharmF7Hb.json',
      text: i18n.global.t('I recommend'),
      active: module?.value?.useReactions?.find(
        (reaction) => reaction?.type === 'recommend'
      )?.selected,
    },
  ];
});
</script>
