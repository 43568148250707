<template>
  <v-dialog
    v-model="dialogDeleteModule"
    content-class="!rounded-[12px] bg-white"
    :width="isMobile ? 300 : 400"
  >
    <template #default>
      <div class="w-full flex flex-col p-6 gap-8">
        <!-- Icon and text -->
        <div class="flex flex-col items-center gap-4">
          <!-- icon -->
          <Icon
            :icon="trainings?.length ? 'noto:warning' : 'mdi:trash-can-outline'"
            height="32"
            width="32"
          />

          <!-- Title & description -->
          <div class="flex flex-col items-center self-stretch gap-4">
            <p class="text-fakeBlack text-xl font-medium text-center">
              {{ trainings.length ? $t('This module is part of one or more trainings.') : $t('Are you sure you want to delete this module ?') }}
            </p>

            <p class="text-darkGrey text-sm font-normal text-center">
              {{ trainings.length ? $t('If this module is the only content of a training, the training will be deleted.') : $t('This is a permanent action.') }}
            </p>
          </div>
        </div>

        <!-- Training List -->
        <div
          v-if="trainings.length"
          class="flex flex-col gap-2"
        >
          <div class="text-sm">
            {{ $t('Training(s) containing only this module :') }}
          </div>
          <div
            class="flex flex-col gap-2"
          >
            <div
              v-for="(training, idx) in trainings"
              :key="idx"
              class="rounded-lg border border-light-grey p-2 text-medium text-xs"
            >
              {{ training.title }}
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="flex flex-col items-start gap-3 self-stretch lg:!flex-row">
          <v-btn
            variant="outlined"
            color="fakeBlack"
            class="normal-case text-xs w-full lg:!flex-1"
            :text="trainings.length ? $t('Cancel') : $t('No, cancel')"
            @click="dialogDeleteModule = false"
          />

          <v-btn
            variant="flat"
            color="negativeRed"
            class="normal-case text-xs w-full lg:!w-1/2"
            :text="trainings.length ? $t('Delete this module') : $t('Yes, delete this module')"
            @click="emit('delete-module')"
          />
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';

const props = defineProps({
  trainings: {
    type: Array,
    default: () => []
  }
})

const dialogDeleteModule = ref(false)

const { isMobile } = storeToRefs(useMobileStore())

const emit = defineEmits(['delete-module'])

defineExpose({
  dialogDeleteModule
})
</script>