import { defineComponent, defineEmits } from 'vue';
import i18n from '@/plugins/i18n.js';
import { useBreakpointsSeven } from '@/tools/breakpoints';
import { useRoute, useRouter } from 'vue-router';
import { useRender } from '../../../tools/useRender';
import {
  TopBarLearnModuleTypes,
  SvnProQuizAnswerLearnItemStates,
  SvnProSectionQuizLearnCorrectionType,
  SvnProCardSectionQuizResultLearnType,
  ComponentSizes,
} from 'svn-ui-library/components';
import Submission from '@/models/submission';

export default defineComponent({
  props: {
    quizId: {
      type: Number,
      default: null,
    },
    currentModuleId: {
      type: Number,
      default: null,
    },
    currentModuleTitle: {
      type: String,
      default: null,
    },
    comeFromTraining: {
      type: Number,
      default: null,
    },
    hasNextModule: {
      type: Number,
      default: null,
    },
    submission: {
      type: Submission,
      default: null,
    },
  },

  setup(props, { emit }) {
    if (props.quizId === null) throw "quizId can't be null";
    if (props.currentModuleId === null) throw "module id can't be null";
    if (props.currentModuleTitle === null) throw "module title can't be null";
    if (props.submission === null) throw "submission can't be null";

    const router = useRouter();
    const route = useRoute();
    const breakpointsSeven = useBreakpointsSeven();

    useRender(() => {
      return props.submission.acquired() ? (
        props.comeFromTraining && props.hasNextModule ? ( // when submission is at 100% and come from training and has next module
          <svn-pro-card-section-quiz-result-learn
            type={SvnProCardSectionQuizResultLearnType.good}
            size={
              breakpointsSeven.greater('sm').value
                ? ComponentSizes.default
                : ComponentSizes.compact
            }
            title={i18n.global.t(
              props?.submission?.getTitleTextCardSectionQuizResultLearn() || ''
            )}
            hasSecondaryAction={true}
            hasTertiaryAction={false}
            description={i18n.global.t(
              props?.submission?.getDescTextCardSectionQuizResultLearn() || ''
            )}
            primaryText={i18n.global.t('Next module')}
            secondaryText={i18n.global.t('Back to training')}
            onPrimaryClick={() => emit('goToModule')}
            onSecondaryClick={() => emit('goToTraining')}
          />
        ) : props.comeFromTraining && !props.hasNextModule ? ( // when submission is at 100% and come from training and doesn't has next module
          <svn-pro-card-section-quiz-result-learn
            type={SvnProCardSectionQuizResultLearnType.good}
            size={
              breakpointsSeven.greater('sm').value
                ? ComponentSizes.default
                : ComponentSizes.compact
            }
            title={i18n.global.t(
              props?.submission?.getTitleTextCardSectionQuizResultLearn() || ''
            )}
            hasSecondaryAction={true}
            hasTertiaryAction={false}
            description={i18n.global.t(
              props?.submission?.getDescTextCardSectionQuizResultLearn() || ''
            )}
            primaryText={i18n.global.t('GO to catalog')}
            secondaryText={i18n.global.t('Back to training')}
            onPrimaryClick={() => emit('goToCatalog')}
            onSecondaryClick={() => emit('goToTraining')}
          />
        ) : (
          // when submission is 100% and doesn't come from training
          <svn-pro-card-section-quiz-result-learn
            type={SvnProCardSectionQuizResultLearnType.good}
            size={
              breakpointsSeven.greater('sm').value
                ? ComponentSizes.default
                : ComponentSizes.compact
            }
            title={i18n.global.t(
              props?.submission?.getTitleTextCardSectionQuizResultLearn() || ''
            )}
            hasSecondaryAction={false}
            hasTertiaryAction={false}
            description={i18n.global.t(
              props?.submission?.getDescTextCardSectionQuizResultLearn() || ''
            )}
            primaryText={i18n.global.t('GO to catalog')}
            onPrimaryClick={() => emit('goToCatalog')}
          />
        )
      ) : props.comeFromTraining && props.hasNextModule ? ( // when submission is not at 100% and come from training and has next module
        <svn-pro-card-section-quiz-result-learn
          type={SvnProCardSectionQuizResultLearnType.fail}
          size={
            breakpointsSeven.greater('sm').value
              ? ComponentSizes.default
              : ComponentSizes.compact
          }
          title={i18n.global.t(
            props?.submission?.getTitleTextCardSectionQuizResultLearn() || ''
          )}
          hasSecondaryAction={true}
          hasTertiaryAction={true}
          description={i18n.global.t(
            props?.submission?.getDescTextCardSectionQuizResultLearn() || ''
          )}
          primaryText={i18n.global.t('Retry quiz')}
          secondaryText={i18n.global.t('Next module')}
          tertiaryText={i18n.global.t('Back to Module')}
          onPrimaryClick={() =>
            router.push({
              name: 'quiz_edit',
              params: { id: props.quizId },
              query: {
                moduleId: props.currentModuleId,
                moduleTitle: props.currentModuleTitle,
                trainingId: props.comeFromTraining,
              },
            })
          }
          onSecondaryClick={() =>
            router.push({
              name: 'module_show',
              params: { id: props.hasNextModule },
              query: {
                trainingId: props.comeFromTraining,
              },
            })
          }
          onTertiaryClick={() =>
            router.push({
              name: 'module_show',
              params: { id: props.currentModuleId },
              query: {
                trainingId: props.comeFromTraining,
              },
            })
          }
        />
      ) : props.comeFromTraining && !props.hasNextModule ? ( // when submission is not at 100% and come from training and doesn't has next module
        <svn-pro-card-section-quiz-result-learn
          type={SvnProCardSectionQuizResultLearnType.fail}
          size={
            breakpointsSeven.greater('sm').value
              ? ComponentSizes.default
              : ComponentSizes.compact
          }
          title={i18n.global.t(
            props?.submission?.getTitleTextCardSectionQuizResultLearn() || ''
          )}
          hasSecondaryAction={false}
          hasTertiaryAction={true}
          description={i18n.global.t(
            props?.submission?.getDescTextCardSectionQuizResultLearn() || ''
          )}
          primaryText={i18n.global.t('Retry quiz')}
          tertiaryText={i18n.global.t('Back to Module')}
          onPrimaryClick={() =>
            router.push({
              name: 'quiz_edit',
              params: { id: props.quizId },
              query: {
                moduleId: props.currentModuleId,
                moduleTitle: props.currentModuleTitle,
                trainingId: props.comeFromTraining,
              },
            })
          }
          onTertiaryClick={() =>
            router.push({
              name: 'module_show',
              params: { id: props.currentModuleId },
              query: {
                trainingId: props.comeFromTraining,
              },
            })
          }
        />
      ) : (
        // when submission is not at 100% and doesn't come from training
        <svn-pro-card-section-quiz-result-learn
          type={SvnProCardSectionQuizResultLearnType.fail}
          size={
            breakpointsSeven.greater('sm').value
              ? ComponentSizes.default
              : ComponentSizes.compact
          }
          title={i18n.global.t(
            props?.submission?.getTitleTextCardSectionQuizResultLearn() || ''
          )}
          hasSecondaryAction={true}
          hasTertiaryAction={true}
          description={i18n.global.t(
            props?.submission?.getDescTextCardSectionQuizResultLearn() || ''
          )}
          primaryText={i18n.global.t('Retry quiz')}
          secondaryText={i18n.global.t('Back to Module')}
          tertiaryText={i18n.global.t('GO to Catalog')}
          onPrimaryClick={() =>
            router.push({
              name: 'quiz_edit',
              params: { id: props.quizId },
              query: {
                moduleId: props.currentModuleId,
                moduleTitle: props.currentModuleTitle,
                trainingId: props.comeFromTraining,
              },
            })
          }
          onSecondaryClick={() =>
            router.push({
              name: 'module_show',
              params: { id: props.currentModuleId },
              query: {
                trainingId: props.comeFromTraining,
              },
            })
          }
          onTertiaryClick={() => router.push({ name: 'catalog' })}
        />
      );
    });
  },
});
