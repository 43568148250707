<template>
  <v-window-item value="playlist">
    <v-fade-transition>
      <!-- Skeleton loaders -->
      <div
        v-if="loading"
        class="grid grid-cols-1 mx-4 my-4 xl:!mx-0 gap-4 justify-center items-center self-stretch
          sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <skeleton-loader-playlist-card
          v-for="item in 8"
          :key="item"
        />
      </div>

      <!-- Finished loading -->
      <div
        v-else
        class="mx-6 md:!mx-0"
      >
        <!-- Search & Select -->
        <div class="w-full flex gap-4 my-4 flex-wrap justify-start items-center">
          <!-- Search -->
          <svn-pro-text-field
            v-model="searchTermPlaylists"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @input="search"
            class="w[320px]"
            clearable
            clear-icon="custom:mingcute:close-line"
          />

          <!-- Sort & Filter -->
          <div class="flex  jsutify-center items-center">
            <!-- <svn-pro-select
              v-model="selectFilterPlaylists"
              :label="$t('Sort by')"
              class="max-w[320px] min-w[112px]"
              :items="selectFilterList"
              item-title="display"
              item-value="value"
              @update:model-value="search"
            /> -->

            <!-- Filter -->
            <svn-pro-menu menuClass="p-0">
              <template #activator="{ props }">
                <svn-pro-button
                  v-bind="props"
                  :active="selectedThemesListPlaylists?.length > 0"
                  :text="`${$t('Filter')} ${selectedThemesListPlaylists?.length ? `(${selectedThemesListPlaylists?.length})` : ''}`"
                  prepend-icon="custom:mingcute:filter-2-line"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <v-list-item :active="false" class="!p-0" @click.stop> <!-- Prevent click propagation -->
                  <svn-pro-combobox
                    v-model="selectedThemesListPlaylists"
                    :items="themes"
                    item-title="name"
                    :label="$t('Themes')"
                    class="!w-[210px] p-4"
                    :clearable="false"
                    multiple
                    return-object
                    @update:model-value="search"
                  />
                </v-list-item>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Filter results -->
        <div
          v-if="selectedThemesListPlaylists?.length > 0"
          class="flex justify-between items-start self-stretch pt-4 xl:!px-0 xl:flex-wrap"
        >
          <!-- Themes list -->
          <div
          v-if="selectedThemesListPlaylists?.length"
            class="flex flex-wrap gap-2"
          >
            <svn-pro-chip
              v-for="theme in selectedThemesListPlaylists"
              key="entityTag.id"
              class=""
              :text="theme.name"
              is-slot-append="true"
            >
              <template #append>
                <Icon
                  icon="mingcute:close-line"
                  width="18"
                  height="18"
                  class="ml-2 cursor-pointer"
                  @click="toggleThemeSelection(theme)"
                />
              </template>
            </svn-pro-chip>
          </div>

          <!-- Clear all themes selected -->
          <svn-pro-button
            v-if="selectedThemesListPlaylists?.length > 1"
            @click="clearAllSelectedThemes"
            variant="text"
            :text="$t('Clear all')"
          />
        </div>

        <!-- Playlists card list -->
        <v-infinite-scroll
          :items="learnPlaylists"
          class="overflow-hidden"
          color="primary"
          @load="loadMoreData"
        >
          <div class="flex flex-wrap md:!grid md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-4 w-full gap-4 justify-center md:!justify-start items-center">
            <svn-pro-card-learn
              v-for="(playlist) in learnPlaylists"
              :key="playlist?.id"
              card-type="playlist"
              :playlistImages="playlist?.cover_urls?.map(cover_url => cover_url[500] )"
              :cardTitle="playlist?.title"
              :shareable="true"
              :favoriteIcon="playlist?.connected_user_favorited  ? 'custom:mingcute:heart-fill' : 'custom:mingcute:heart-line'"
              :editable="amICreatorOfEntityOrAdmin(playlist?.creator_id)"
              :count="playlist?.learn_modules_count"
              :is-favorited="playlist?.connected_user_favorited"
              shareableIcon="custom:mingcute:link-line"
              @on-favorite="togglePlaylistFavorite(playlist?.id)"
              @on-share="copyPlaylistLink(playlist?.id)"
              @go-to-item-show="goToPlaylistShowEdit(playlist?.id, playlist?.title)"
            >
              <template #menu>
                <svn-pro-menu>
                  <template #activator>
                    <svn-pro-icon-button
                      icon="custom:mingcute:more-2-line"
                      variant="elevated"
                      color="surface"
                    />
                  </template>

                  <template #dropdown>
                    <v-list-item
                      value="edit_module"
                      density="compact"
                      :active="false"
                      @click="editPlaylist(playlist)"
                    >
                      {{ $t('Update playlist') }}
                    </v-list-item>

                    <v-list-item
                      value="delete_module"
                      density="compact"
                      :active="false"
                      @click="openDialogDeletePlaylist(playlist)"
                      class="text-error"
                    >
                      {{ $t('Delete playlist') }}
                    </v-list-item>
                  </template>
                </svn-pro-menu>
              </template>
            </svn-pro-card-learn>
          </div>

          <template #empty />
        </v-infinite-scroll>

        <div
          class="flex text-center justify-center items-center"
          v-if="!learnPlaylists?.length"
        >
          <svn-pro-empty-states
            :actions="isManagerCreatorOrAbove()"
            :variant="searchTermPlaylists ? 'results' : 'index'"
            :title="$t('No playlist yet')"
            :size="isMdScreen ? 'default' : 'compact'"
            :action-primary-title="$t('New Playlist')"
            @click-primary="emit('go-to-new-playlist')"
            prepend-primary="custom:mingcute:add-line"
            :supporting-text="emptyStateSupportingText"
          />
        </div>
      </div>
    </v-fade-transition>

  </v-window-item>

  <!-- Dialog edit Playlist -->
  <dialog-delete-content
    v-if="playlistToDelete?.trainings?.length"
    ref="deletePlaylistDialog"
    :items="playlistToDelete?.trainings"
    :title="$t(`Playlist will be deleted with their trainings`)"
    :description="$t(`If this playlist is the only content of a training, the training will be deleted. Training(s) containing only this playlist :`)"
    @delete-content="deleteLearnPlaylist"
  />

  <svn-pro-dialog-validation
    v-else
    ref="deletePlaylistDialog"
    icon="noto:warning"F
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Playlist will be deleted`)"
    :content-text="$t('Deleted playlists are stored for 30 days. After this period, they will be permanently deleted.')"
    @click-primary-button="deleteLearnPlaylist"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </svn-pro-dialog-validation>

    <!-- Dialog edit Playlist -->
    <dialog-edit-playlist
      ref="editPlaylistDialog"
      :title="selectedLearnPlaylist?.title"
      :description="selectedLearnPlaylist?.description"
      @update-playlist="updateLearnPlaylist"
    />
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { storeToRefs } from "pinia";
import { ref, onMounted, computed } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { useLearnPlaylistStore } from "@/store/learn-playlist.js";
import router from '@/router';
import SkeletonLoaderPlaylistCard from '@/components/skeletonLoaders/SkeletonLoaderPlaylistCard.vue';
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import { debounce } from 'lodash';
import i18n from '@/plugins/i18n';
import { useUserStore } from "@/store/user.js";
import DialogDeleteContent from '@/components/BktPopUp/Dialogs/learn/DialogDeleteContent.vue';
import DialogEditPlaylist from '@/components/BktPopUp/Dialogs/learn/DialogEditPlaylist.vue';

const { isManagerCreatorOrAbove, amICreatorOfEntityOrAdmin } = useUserStore();

const props = defineProps({
  themes: { type: Array, default: null },
})

const emit = defineEmits(['go-to-new-playlist'])

onMounted(async() => {
  try {
    await fetchPlaylists().then((res) => {
      totalPages.value = res.data.meta.pagination.total_pages
      loading.value = false
      learnPlaylists.value = res.data.learn_playlists
    })
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-10')
    snackbar.setMsg('Error fetching playlists')
    snackbar.displaySnackBar()
  }
})

const page = ref(1)

const totalPages = ref(null)

const loading = ref(true)

const deletePlaylistDialog = ref(false)

const playlistToDelete = ref(null)

const searchTermPlaylists = ref('')

const selectedThemesListPlaylists = ref([])

const filterByFavorites = ref(false)

const snackbar = useSnackbar()
const editPlaylistDialog = ref(false)
const selectedLearnPlaylist = ref(null)

const { fetchPlaylists, fetchPlaylistsOnlyWithPages, toggleFavorite, deletePlaylist, restorePlaylist, deletePlaylistPermanently, updatePlaylist } = useLearnPlaylistStore();

const breadcrumbsStore = useBreadcrumbsStore();

const { addNode, update } = breadcrumbsStore

const { isMobile, isMdScreen } = storeToRefs(useMobileStore())

const { learnPlaylists } = storeToRefs(useLearnPlaylistStore());

const deletedItems = ref(null)

const selectFilterList = ref([
  {
    display: i18n.global.t("Decreasing publication date"),
    value: "desc-publication",
  },
  {
    display: i18n.global.t("Increasing publication date"),
    value: "asc-publication",
  },
  {
    display: i18n.global.t("Increasing acquisition date"),
    value: "asc-acquisition",
  },
  {
    display: i18n.global.t("Decreasing acquisition date"),
    value: "desc-acquisition",
  },
])


const editPlaylist = (playlist) => {
  selectedLearnPlaylist.value = playlist
  editPlaylistDialog.value.dialogEditPlaylist = true
}

const updateLearnPlaylist = async(title, description) => {
  try {
    await updatePlaylist(selectedLearnPlaylist.value.id, title, description)
    learnPlaylists.value = learnPlaylists.value.map((playlist) => {
      if (playlist.id === selectedLearnPlaylist.value?.id) {
        playlist.title = title
        playlist.description = description
      }
      return playlist
    })
    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Playlist has been updated successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error updating the playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-10')
    snackbar.displaySnackBar()
  }

  editPlaylistDialog.value.dialogEditPlaylist = false
}

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}





const copyPlaylistLink = (id) => {
  const link = router.resolve({  name: 'playlist_show', params: { id: id } })

  navigator.clipboard.writeText(new URL(link.href, window.location.origin).href).then(() => {
    snackbar.setBgColor('onSurface')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.setMsg('Link copied to clipboard.')
    snackbar.displaySnackBar()
  }).catch(() => {
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
    snackbar.displaySnackBar('Error copying link to clipboard')
  })
}




const toggleThemeSelection = debounce(async(item) => {
  const found = selectedThemesListPlaylists?.value?.find(theme => theme?.id === item?.id);

  if (found) {
    selectedThemesListPlaylists?.value?.splice(selectedThemesListPlaylists?.value?.findIndex((theme) => theme?.id === item?.id), 1);
  }
  else {
    selectedThemesListPlaylists?.value?.push(item);
  }
  search()
}, 200)

const togglePlaylistFavorite = debounce(async(playlistId) => {
  try {
    const found = learnPlaylists?.value?.find(playlist => playlist?.id === playlistId)

    if (found) {
      toggleFavorite(playlistId)

      if (!found?.connected_user_favorited) {
        snackbar.setMsg('Playlist added to favorites.')
      } else {
        snackbar.setMsg('Playlist removed from favorites.')
      }
      snackbar.setBgColor('onSurface')
      snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
      snackbar.displaySnackBar()
    }
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error toggling favorite')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()
  }
}, 200)

const asignPlaylistToDelete = async(playlist) => {
  playlistToDelete.value = playlist
}

const openDialogDeletePlaylist = async(playlist) => {
  await asignPlaylistToDelete(playlist)

  if (playlist?.trainings?.length) {
    deletePlaylistDialog.value.deleteDialog = true
  } else {
    deletePlaylistDialog.value.dialogRef.dialog = true
  }
}

const deleteLearnPlaylist = async() => {
  try {
    await deletePlaylist(playlistToDelete.value?.id)

    deletePlaylistDialog.value = false

    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Playlist has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    router.push({ name: "catalog" })
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error deleting playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
  playlistToDelete.value = null
}

const goToPlaylistShowEdit = (id, title) => {
  updateBreadcrumbs(title, id)
  router.push({ name: 'playlist_show', params: { id: id } })
}

const clearAllSelectedThemes = () => {
  selectedThemesListPlaylists.value = [];
}

const search = debounce(async() => {
  try {
    const res = await fetchPlaylists(searchTermPlaylists.value,
      selectedThemesListPlaylists?.value?.map(theme => theme?.id), filterByFavorites.value)
    learnPlaylists.value = res.data.learn_playlists
    totalPages.value = res.data.meta.pagination.total_pages
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error fetching playlists')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}, 200)

const updateBreadcrumbs = (title, playlistId) => {
  addNode(title, `/learns/module/${playlistId}/show`)
  update()
}

const loadMoreData = async({ done }) => {
  if (page.value < totalPages.value) {
    try {
      const res = await fetchPlaylistsOnlyWithPages(page.value + 1, searchTermPlaylists.value)
        if (res.data.learn_playlists.length) {
          learnPlaylists.value = [...learnPlaylists.value, ...res.data.learn_playlists]
          totalPages.value = res.data.meta.pagination.total_pages
        }
        done('ok')
        page.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const emptyStateSupportingText = computed(() => {
  return searchTermPlaylists.value ? i18n.global.t('Oops, we didn’t find any results matching your search.') :
  (isManagerCreatorOrAbove() ? i18n.global.t(`There are no playlists. Create your first playlist!`) : i18n.global.t(`All playlists availables will show up here.`))
})
</script>