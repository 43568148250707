import axiosService from '@/tools/axios-service';
import LearnModule from '@/models/learn_module';

class ModuleApi {
  async index({ page, text, favoritableModules = false, sortBy = 'desc-publication' }) {
    const res = await axiosService.get(`/api/v1/learn/modules`, {
      params: {
        'page[number]': page,
      },
    });

    return LearnModule.fromJson(res.data);
  }

  async get(id) {
    const res = await axiosService.get(`/api/v1/learn/modules/${id}`);

    return LearnModule.fromJson(res.data);
  }

  async paginateByTraining(id, trainingId) {
    const res = await axiosService.get(`/api/v1/learn/modules/${id}/paginate_by_training`, {
      params: {
        training_id: trainingId,
      },
    });

    return LearnModule.fromJson(res.data);
  }
}

const instance = new ModuleApi();

export default instance;
