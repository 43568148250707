<template>
  <div
    v-for="(content, index) in trainingContent"
    :key="index"
    class="w-full flex flex-col gap-4"
  >
    <!-- Content is a Module -->
    <svn-pro-card-learn
      v-if="content?.content_type === 'Learn::Module'"
      card-type="module_in_playlist"
      :card-size="isMdScreen ? 'default' : 'compact'"
      :card-title="content?.title"
      :card-image="content?.cover_url?.['150']"
      :duration="content?.duration"
      :editable="false"
      :themes="content?.module_themes?.map(theme => theme?.name)"
      :is-acquired="content?.submission_status === 'acquired'"
      @go-to-item-show="goToContentShow(content)"
    />

    <!-- Content is a Playlist -->
    <svn-pro-card-learn
      v-if="content?.content_type === 'Learn::Playlist'"
      card-type="playlist_in_training_show"
      :card-title="content?.title"
      :count="content?.learn_modules_count"
      :card-size="isMdScreen ? 'default' : 'compact'"
      :playlist-modules="content?.learn_modules"
      @go-to-item-show="goToContentShow($event)"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const { isMdScreen } = storeToRefs(useMobileStore());

const props = defineProps({
  trainingContent: { type: Array, default: null },
})

const emit = defineEmits(['go-to-content-show'])

const goToContentShow = (content) => {
  emit('go-to-content-show', content)
}
</script>