import LearnTraining from '@/models/learn_training';

export default class User {
  constructor({
    id,
    firstname,
    lastname,
    fullname,
    email,
    avatar,
    jobTitle,
    manager,
    completedLearnModulesCount,
    completedLearnTrainingCount,
    todoLearnTrainingCount,
    learnTrainings,
  }) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.fullname = fullname;
    this.email = email;
    this.avatar = avatar;
    this.jobTitle = jobTitle;
    this.manager = manager;
    this.completedLearnModulesCount = completedLearnModulesCount;
    this.completedLearnTrainingCount = completedLearnTrainingCount;
    this.todoLearnTrainingCount = todoLearnTrainingCount;
    if (learnTrainings) {
      this.learnTrainings = [];

      learnTrainings.forEach((item) => this.learnTrainings.push(LearnTraining.fromJson(item)));
    }
  }

  static fromJson(data) {
    return new User({
      id: data?.id,
      firstname: data?.firstname,
      lastname: data?.lastname,
      fullname: data?.fullname,
      email: data?.email,
      avatar: data?.avatar ?? null,
      jobTitle: data?.job_title ?? null,
      manager: data?.manager ?? null,
      completedLearnModulesCount: data?.['completed_learn_modules_count'],
      completedLearnTrainingCount: data?.['completed_learn_training_count'],
      todoLearnTrainingCount: data?.['todo_learn_training_count'],
      learnTrainings: data?.['learn_trainings'],
    });
  }

  getFullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  hasManager() {
    return this.manager !== null;
  }
}
