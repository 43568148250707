<template>
  <svn-pro-modal
    v-model="dialogAddToPlaylist"
    :width="isMdScreen ? 480 : undefined"
    :title="
      $t('Add this module to playlist ()', { selectedPlaylists: selectedPlaylists?.length || 0 })
    "
    no-click-animation
    :sticky-bottom="isMdScreen ? true : false"
    :close-button-closes-modal="false"
    @click-close="dialogAddToPlaylist = false"
    @click-outside="deleteDialog = false"
    @click-secondary-button="dialogAddToPlaylist = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
    <template #text>
      <div class="w-full flex flex-col gap-4 h-full md:!h-[440px]">
        <div class="w-full flex flex-col md:!flex-row items-start md:!items-center gap-4">
          <svn-pro-text-field
            v-model="searchText"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @update:model-value="search"
            clearable
            class="w-full"
            clear-icon="custom:mingcute:close-line"
          />
        </div>

        <template v-if="loading">
          <div class="w-full h-full flex flex-col items-center justify-center">
            <svn-pro-progress-circular :size="48" :width="5" indeterminate />
          </div>
        </template>

        <div v-else>
          <v-infinite-scroll
            v-if="learnPlaylists?.length"
            :items="learnPlaylists"
            class="overflow-hidden w-full"
            color="primary"
            @load="loadMoreData"
          >
            <v-list select-strategy="classic" color="primary">
              <v-list-item
                v-for="(content, index) in learnPlaylists"
                :key="index"
                :active="false"
                :value="`${content?.content_type}_${content?.id}`"
                class="border-b border-b-[#767680] border-opacity-100"
              >
                <content-card-item :content="content" @toggle-content="toggleContent(content)" />

                <template #append>
                  <v-list-item-action>
                    <v-checkbox-btn
                      color="primary"
                      :class="
                        selectedPlaylists?.some(
                          (data) =>
                            data?.id == content?.id && data?.content_type == content?.content_type,
                        )
                          ? 'text-primary'
                          : false
                      "
                      :model-value="
                        selectedPlaylists?.some(
                          (data) =>
                            data?.id == content?.id && data?.content_type == content?.content_type,
                        )
                          ? true
                          : false
                      "
                      @update:model-value="toggleContent(content)"
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
            <template #empty />
          </v-infinite-scroll>
          <div
            v-else-if="!learnPlaylists?.length && !searchText"
            class="flex flex-col px-6 py-4 gap-2 justify-center items-center h-[183px] md:!h-[388px] text-center"
          >
            <div>
              <Icon icon="noto:face-with-monocle" height="48" width="48" />
            </div>

            <svn-pro-text body-large regular color="onSurfaceVariant">
              {{ $t(`You dont have any playlist for the moment.`) }}
            </svn-pro-text>
          </div>

          <div
            v-else-if="!learnPlaylists?.length && searchText"
            class="flex flex-col px-6 py-4 gap-2 justify-center items-center h-[183px] md:!h-[388px] text-center"
          >
            <div>
              <Icon icon="noto:face-with-monocle" height="48" width="48" />
            </div>

            <svn-pro-text body-large regular color="onSurfaceVariant">
              {{ $t('Oops, we didn’t find any results matching your search.') }}
            </svn-pro-text>
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';
import { useSnackbar } from '@/store/snackbar';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import PlaylistCardListItem from '@/components/trainingApp/PlaylistCardListItem.vue';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import ContentCardItem from '@/components/trainingApp/ContentCardItem.vue';
import axiosService from '@/tools/axios-service.js';

const props = defineProps({
  moduleId: { type: Number, default: null },
});

const emit = defineEmits(['close-dialog']);

const { isMdScreen } = storeToRefs(useMobileStore());

const { learnPlaylists, totalPlaylistPages } = storeToRefs(useLearnPlaylistStore());

const { fetchPlaylists, togglePlaylistInfos } = useLearnPlaylistStore();

const dialogAddToPlaylist = ref(false);

const searchText = ref('');
const playlistPage = ref(1);
const selectedPlaylists = ref([]);
const snackbar = useSnackbar();

const toggledialogAddToPlaylist = debounce(async (playlist) => {
  try {
    await togglePlaylistInfos(playlist, props?.moduleId);

    snackbar.setBgColor('onSurface').setMsg('Module successfully added to playlist.').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar();
  } catch (error) {
    console.log(error);
    snackbar.setBgColor('onSurface').setMsg('Error adding module to playlist(s)').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar();
  }
}, 250);

const search = debounce(async () => {
  loading.value = true
  try {
    const res = await fetchPlaylists(searchText.value);
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error fetching playlists').setCustomClass(isMdScreen.value ? 'mb-4' : 'mb-[72px]').displaySnackBar();
  }
  loading.value = false
}, 250);

const loadMoreData = async ({ done }) => {
  if (playlistPage.value < totalPlaylistPages.value) {
    try {
      const res = await axiosService.get('api/v1/learn/playlists', {
        params: {
          text: searchText.value,
          'page[number]': playlistPage.value + 1,
        },
      });
      totalPlaylistPages.value = res.data.meta.pagination.total_pages;
      if (res.data.learn_playlists.length) {
        learnPlaylists.value = [...learnPlaylists.value, ...res.data.learn_playlists];
      }
      done('ok');
      playlistPage.value++;
    } catch (error) {
      done('error');
    }
  } else {
    done('empty');
  }
};

const toggleContent = async (content) => {
  // Find the index of the content with the same id in the selectedPlaylists array
  const index = selectedPlaylists.value.findIndex((item) => item.id === content.id);

  if (index !== -1) {
    // If the content exists, remove it
    selectedPlaylists.value.splice(index, 1);
  } else {
    // If the content does not exist, add it
    selectedPlaylists.value.push(content);
  }
  await toggledialogAddToPlaylist(content);
};

const fillSelectedPlaylists = () => {
  selectedPlaylists.value = [];
  learnPlaylists.value.forEach((learnPlaylist) => {
    const isPlaylistSelected = selectedPlaylists.value.find((p) => p.id === learnPlaylist.id);

    if (learnPlaylist?.learn_module_ids?.includes(props.moduleId) && !isPlaylistSelected) {
      selectedPlaylists.value.push(learnPlaylist);
    }
  });
};

watch(learnPlaylists, () => {
  fillSelectedPlaylists();
});

watch(props, () => {
  if (props.moduleId) {
    fillSelectedPlaylists();
  }
});
defineExpose({
  dialogAddToPlaylist,
});
</script>
