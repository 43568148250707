<template>  
  <div
    :class="[drawerLarge ? 'w-[344px]' : 'w-0 opacity-0', isMobile ? 'w-full top-0 h-full' : 'h-[calc(100vh-129px)] top-[129px] border-l border-[#767680] border-opacity-100']"
    class="fixed right-0 overflow-hidden flex flex-col transition-all"
  >
    <!-- Header section -->
    <div
      class="flex pr-3 pt-3 pb-4 justify-center items-center self-stretch relative"
      :class="isInThread ? 'pl-1' : 'pl-6'"
    >
      <svn-pro-icon-button
        v-if="isInThread"
        variant="text"
        color="onSurfaceVariant"
        icon="custom:mingcute:arrow-left-line"
        @click="closeThread"
      />

      <div class="flex flex-1 items-start">
        <svn-pro-title v-if="isInThread"h5 regular>
          {{ 'Thread' }} {{ `(${selectedComment?.replies?.length})` }}
        </svn-pro-title>

        <svn-pro-title v-else h5 regular>
          {{ $t('Comments ()', { count: learnModulesComments?.length || 0 }) }}
        </svn-pro-title>
      </div>

      <svn-pro-icon-button
        variant="text"
        color="onSurfaceVariant"
        icon="custom:mingcute:close-line"
        @click="closeDrawerAndThread"
      />
    </div>

    <!-- Reply is clicked, enter Thread -->
    <div
      v-if="isInThread"
      class="flex h-full w-full overflow-y-auto overflow-x-hidden"
    >
      <div class="w-full h-full flex flex-col px-5 pt-2 pb-6 gap-4">
        <svn-pro-card-comment
          :has-cta="false"
          :comment="selectedComment?.text"
          :date="selectedComment?.created_at"
          :avatar="selectedComment?.user?.avatar?.['50']"
          :username="selectedComment?.user?.fullname"
          :deletable="amICreatorOfEntityOrAdmin(learnModule?.creator_id) || comment?.user?.id === id"
          :is-module-author="selectedComment?.user?.module_creator"
          @delete-clicked="onDeleteComment(selectedComment)"
        />

        <div class="w-full flex items-center gap-2">
          <svn-pro-text body-medium color="onSurfaceVariant" class="whitespace-nowrap">
            {{ selectedComment?.replies?.length ? selectedComment?.replies?.length > 1 ? `${selectedComment?.replies?.length} ${$t('replies')}` : `${selectedComment?.replies?.length} ${$t('reply')}` : `${selectedComment?.replies?.length} ${$t('reply')}` }}
          </svn-pro-text>

          <svn-pro-divider color="[#767680]" class="w-full" />
        </div>

        <div v-if="selectedComment?.replies?.length" class="w-full flex flex-col gap-6">
          <svn-pro-card-comment
            v-for="(comment, index) in selectedComment?.replies"
            :key="index"
            :has-cta="false"
            :comment="comment?.text"
            :date="comment?.created_at"
            :avatar="comment?.user?.avatar?.['50']"
            :username="comment?.user?.fullname"
            :deletable="amICreatorOfEntityOrAdmin(learnModule?.creator_id) || comment?.user?.id === id"
            :is-module-author="comment?.user?.module_creator"
            @delete-clicked="onDeleteCommentInThread(comment)"
          />              
        </div>
      </div>
    </div>

    <!-- List of all comments (NOT IN THREAD) -->
    <div
      v-else
      class="flex h-full w-full overflow-y-auto overflow-x-hidden"
    >
      <!-- There are comments -->
      <div
        v-if="learnModulesComments?.length"
        class="w-full flex flex-col px-5 pt-2 pb-6 gap-4"
      >
        <div
          v-for="(comment, index) in learnModulesComments"
          :key="index"
          class="w-full flex flex-col gap-4"
        >
          <svn-pro-card-comment
            has-cta
            :comment="comment?.text"
            :date="comment?.created_at"
            :replies="comment?.replies?.length"
            :username="comment?.user?.fullname"
            :reply-button-text="getReplyText(comment?.replies?.length)"
            :tooltip-text="$t('Delete comment')"
            :avatar="comment?.user?.avatar?.['50']"
            :deletable="amICreatorOfEntityOrAdmin(learnModule?.creator_id) || comment?.user?.id === id"
            :is-module-author="comment?.user?.module_creator"
            @action-clicked="openThreadMenu(comment)"
            @delete-clicked="onDeleteComment(comment)"
          />
          
          <svn-pro-divider v-if="index !== learnModulesComments?.length - 1" color="[#767680]" class="w-full" />
        </div>
      </div>

      <!-- No comments -->
      <div
        v-else
        class="w-full h-full flex flex-col justify-center items-center self-stretch flex-1"
      >
        <svn-pro-text body-large regular>
          {{ $t('No comments yet') }}
        </svn-pro-text>
      </div>
    </div>

    <!-- Input section -->
    <div class="w-full h-[80px] flex pl-4 py-3 pr-3 gap-2 items-center border-t border-borderColor border-opacity-100">
      <!-- Text area to add comment -->
      <v-textarea
        v-model="userComment"
        variant="outlined"
        class="flex-1"
        color="primary"
        hide-details="auto"
        :placeholder="$t('Add a comment')"
        :auto-grow="false"
        :no-resize="true"
        :rows="1"
      />

      <!-- Submit comment button -->
      <svn-pro-icon-button
        variant="text"
        color="onSurfaceVariant"
        :disabled="!userComment?.length"
        icon="custom:mingcute:send-line"
        @click="isInThread ? addThreadComment() : addComment()"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import i18n from "@/plugins/i18n.js";
import { useUserStore } from '@/store/user';
import { useLearnModuleStore } from "@/store/learn-module";

const { amICreatorOfEntityOrAdmin } = useUserStore();

const { id } = storeToRefs(useUserStore());
const { learnModulesComments, learnModule } = storeToRefs(useLearnModuleStore());

const props = defineProps({
  isMobile: { type: Boolean, default: false },
  drawerLarge: { type: Boolean, default: false },
})

const emit = defineEmits(['delete-comment', 'delete-comment-in-thread', 'add-comment-reply', 'add-comment-thread-reply', 'close-drawer', 'update:modelValue'])

const userComment = ref('');
const isInThread = ref(false);
const selectedComment = ref({});

const closeDrawerAndThread = () => {
  emit('close-drawer')
  if (isInThread?.value) {
    isInThread.value = false
    selectedComment.value = {}
  }
}

const openThreadMenu = (comment) => {
  selectedComment.value = comment
  isInThread.value = true
}

const closeThread = () => {
  selectedComment.value = {}
  isInThread.value = false
}

const addComment = () => {
  emit('add-comment-reply', userComment?.value)
  userComment.value = null
}

const onDeleteComment = (comment) => {
  emit('delete-comment', comment)
}

const onDeleteCommentInThread = (comment) => {
  emit('delete-comment-in-thread', comment)
}

const addThreadComment = () => {
  emit('add-comment-thread-reply', {
    messageable_id: selectedComment?.value?.messageable_id,
    reaction_type: "",
    comment: userComment?.value,
    comment_id: selectedComment?.value?.id
  })
  userComment.value = null
}

const getReplyText = (replies) => {
  if (replies === 0) {
    return i18n.global.t('Reply')
  } else if (replies === 1) {
    return i18n.global.t('1 reply')
  } else {
    return i18n.global.t(`${replies} replies`)
  }
}

watch(learnModulesComments, (newValue, oldValue) => {
  if (newValue) {
    selectedComment.value.replies = newValue?.find(comment => comment?.id === selectedComment?.value?.id)?.replies
    learnModulesComments.value = newValue
  }
})
</script>