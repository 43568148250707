import SevenImgObject from '@/models/seven_img_object';
import LearnModule from '@/models/learn_module';

export default class LearnTraining {
  constructor({
    id,
    allModulesDuration,
    description,
    status,
    title,
    moduleCompletion,
    moduleCount,
    moduleThemes,
    coverUrl,
    lastModuleAcquiredOn,
  }) {
    this.id = id;
    this.allModulesDuration = allModulesDuration;
    this.description = description;
    this.status = status;
    this.title = title;
    this.moduleCompletion = moduleCompletion;
    this.moduleCount = moduleCount;
    this.moduleThemes = moduleThemes;
    if (coverUrl) {
      this.coverUrl = SevenImgObject.fromJson(coverUrl);
    }
    if (lastModuleAcquiredOn) {
      this.lastModuleAcquiredOn = LearnModule.fromJson(lastModuleAcquiredOn);
    }
  }

  static fromJson(data) {
    return new LearnTraining({
      id: data?.['id'],
      allModulesDuration: data?.['all_modules_duration'],
      description: data?.['description'],
      status: data?.['status'],
      title: data?.['title'],
      moduleCompletion: data?.['module_completion'],
      moduleCount: data?.['module_count'],
      moduleThemes: data?.['module_themes'],
      coverUrl: data?.['cover_url'],
      lastModuleAcquiredOn: data?.['last_module_acquired_on'],
    });
  }
}
