<template>
  <div :id="question.id" class="w-full flex flex-col gap-6">
    <svn-pro-form-block
      type="textarea"
      :title="question?.text"
      :has-editable-area="false"
      :question-type="question?.type"
      :description="question?.description"
      :size="isMdScreen ? 'default' : 'compact'"
      :has-divider="question?.description?.length ? true : false"
      :has-description="question?.description?.length ? true : false"
    />

    <template v-if="snapshotsCreated?.length">
      <svn-pro-card-interview-target
        v-for="(draftTarget, idx) in snapshotsCreated"
        :key="idx"
        type="create_proposal"
        :size="isMdScreen ? 'default' : 'compact'"
        tag-leading-item="point"
        :action-disabled="locked"
        :delete-disabled="locked"
        :target-title="draftTarget?.title"
        :target-deadline="draftTarget?.due_date"
        :target-description="draftTarget?.description"
        :target-target-value="getTagText(draftTarget?.objective_indicator?.status)"
        :target-objective-indicator="draftTarget?.objective_indicator"
        :button-text="$t('Edit target')"
        button-variant="tonal"
        interview-status="submitted"
        delete-icon="custom:mingcute:delete-2-line"
        create-prepend-icon="custom:mingcute:edit-2-line"
        @click-action="setSelectedTarget(draftTarget)"
        @click-delete="deleteTargetDraft(draftTarget?.id)"
      />
    </template>

    <!-- Create new target if locked -->
    <svn-pro-card-interview-target
      v-if="!locked"
      type="create"
      :size="isMdScreen ? 'default' : 'compact'"
      :button-text="$t('Create new target')"
      @click-action="refModalCreateNewTarget.modalNewTarget.dialog = true"
    />

    <!-- Locked, cannot create new targets -->
    <div
      v-else
      class="flex flex-col justify-center items-center mx-auto my-10"
    >
      <svn-pro-text subtitle-large color="onSurfaceVariant">
        {{ $t('This interview is already submitted, so you cannot add any new targets for the moment.') }}
      </svn-pro-text>
    </div>
  </div>

  <!-- Modal edit createed target -->
  <svn-pro-modal
    ref="refModalEditTarget"
    :title="$t('Edit target')"
    :width="872"
    @click-close="resetSelectedTarget"
    @click-outside="resetSelectedTarget"
  >
    <template #activator>
      {{ null }}
    </template>

    <template #text>
      <target-new
        :target-id="selectedTarget?.id"
        :overlay-mode="true"
        :users-from-overlay="[employee]"
        :is-editing-target="true"
        :create-draft="true"
        @close="closePopUpAndReloadInterview"
        from="interview_answer"
      />
    </template>
  </svn-pro-modal>

  <!-- Modal create new target -->
  <modal-create-new-target
    ref="refModalCreateNewTarget"
    :users-from-overlay="[employee]"
    :overlay-mode="true"
    :create-draft="true"
    :question="question"
    :interview-id="id"
    :campaign-id="campaignId"
    from="interview_answer"
    >
    <template #activator>
      {{ null }}
    </template>
  </modal-create-new-target>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from "pinia";
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from "@/store/mobile";
import { useInterviewStore } from "@/store/interview.js";
import TargetNew from '@/views/roadmaps/Targets/New.vue';
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";
import ModalCreateNewTarget from '../../BktPopUp/Modals/ModalCreateNewTarget.vue';

const props = defineProps(['question']);

const selectedTarget = ref({});
const refModalEditTarget = ref(false);
const refModalCreateNewTarget = ref(null);

const snackbar = useSnackbar();
const { fetchCreatedSnapshots, deleteTargetDraftFromInterviewAnswer } = useTargetSuggestionsStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { snapshotsCreated } = storeToRefs(useTargetSuggestionsStore());
const { id, locked, employee, campaignId } = storeToRefs(useInterviewStore());

onMounted(async() => {
  try {
    await fetchDraftTargets()
  } catch (error) {
    console.log(error)
  }
});

const setSelectedTarget = (target) => {
  selectedTarget.value = target
  refModalEditTarget.value.dialog = true
}

const resetSelectedTarget = () => {
  selectedTarget.value = {}
}

const fetchUpdatedTarget = (newTargetData) => {
  if (newTargetData) {
    fetchDraftTargets()
  }
}

const getTagText = (status) => {
  switch (status) {
    case "completed":
      return "Completed";
    case "in_progress":
      return "In progress";
    case "uncompleted":
      return "Not validated";
    default:
      return "Not set yet";
  }
}

const closePopUpAndReloadInterview = (newTargetData) => {
  refModalEditTarget.value.dialog = false
  fetchUpdatedTarget(newTargetData);
  resetSelectedTarget();
}

const deleteTargetDraft = async(targetDraftId) => {
  try {
    await deleteTargetDraftFromInterviewAnswer(targetDraftId);
    fetchUpdatedTarget(true)
    snackbar.setBgColor('onSurface').setMsg('Target proposal successfully deleted').displaySnackBar();
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error deleting target proposal').displaySnackBar();
  }
}

const fetchDraftTargets = async () => {
  try {
    await fetchCreatedSnapshots(employee?.value?.id, id?.value)
  } catch (e) {
    console.log("couldn't get draft targets", e)
  }
}
</script>