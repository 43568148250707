import axios from "axios";
import { Service } from "axios-middleware";
import router from "../router/index.js";
import { useUserStore } from "@/store/user.js";
import { useImpersonateStore } from "@/store/impersonate.js"

axios.defaults.baseURL = import.meta.env.VITE_BACKEND_DOMAIN;
// ERROR: when I put this to true I cannot access to the header response any more !!!!
// axios.defaults.withCredentials = true
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use((config) => {
  const authHeaders = JSON.parse(window.localStorage.getItem('bktAccess'))
  if (authHeaders) {
    config.headers[config.method] = {
      'access-token': authHeaders['access-token'],
      'token-type': authHeaders['token-type'],
      'client': authHeaders['client'],
      'expiry': authHeaders['expiry'],
      'uid': authHeaders['uid']
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error)
});

axios.interceptors.response.use((response) => {
  if (response.headers['access-token']) {
    const authHeaders = {
      'access-token': response.headers['access-token'],
      'token-type': response.headers['token-type'],
      'client': response.headers['client'],
      'expiry': response.headers['expiry'],
      'uid': response.headers['uid']
    }
    window.localStorage.setItem('bktAccess', JSON.stringify(authHeaders));
  }
  return response;
}, (error) => {
  return Promise.reject(error)
});

axios.emailSignIn = async ({ email, password, timezone }) => {
  const res = await axios.post('/api/v1/devise-token/sign_in', {
    email,
    password,
    timezone
  })

  return res.data.data
}

axios.logout = async () => {
  const { setStates } = useUserStore()
  const { impersonateLogout } = useImpersonateStore()
  await axios.delete('/api/v1/devise-token/sign_out')

  window.localStorage.removeItem('bktAccess')
  setStates({id: null})
  impersonateLogout()
  router.push({ name: 'check' })
  // window.location.href = '/auth/check'
}

axios.verifyToken = async (auth = null) => {
  const authHeaders = auth ?? JSON.parse(window.localStorage.getItem('bktAccess'))
  if (!authHeaders) throw 'You have no token'

  try {
    const res = await axios.get('/api/v1/devise-token/validate_token', {
      params: {
        'access-token': authHeaders['access-token'],
        'token-type': authHeaders['token-type'],
        'client': authHeaders['client'],
        'expiry': authHeaders['expiry'],
        'uid': authHeaders['uid']
      }
    })
    if (auth) {
      window.localStorage.setItem('bktAccess', JSON.stringify(authHeaders));
    }
    return res.data.data
  } catch (e) {
    console.log('verify token', e)
    window.localStorage.removeItem('bktAccess');
    return false
  }
}
axios.resetPass = async (email) => {
  await axios.patch('/api/v1/users/reset_pass', { email })
}
axios.createPass = async (email) => {
  await axios.patch('/api/v1/users/create_pass', { email })
}
axios.resetPassConfirmation = async (token, password) => {
  const res = await axios.patch('/api/v1/users/reset_pass_confirmation', {
    token,
    password
  })

  return res.data
}
axios.createPassConfirmation = async (token, password) => {
  const res = await axios.patch('/api/v1/users/create_pass_confirmation', {
    token,
    password
  })

  return res.data
}

axios.impersonate = async (access_level, email) => {
  const { impersonateLogout } = useImpersonateStore()

  const uninterceptedAxiosInstance = axios.create();
  const bktAuthHeadersImpersonate = JSON.parse(window.localStorage.getItem('bktAccessImpersonate'))
  const bktAuthHeaders = JSON.parse(window.localStorage.getItem('bktAccess'))
  let authHeaders

  if (bktAuthHeadersImpersonate) {
    authHeaders = bktAuthHeadersImpersonate
  } else {
    authHeaders = bktAuthHeaders
    window.localStorage.setItem('bktAccessImpersonate', JSON.stringify(bktAuthHeaders));
  }

  if (access_level) {
    const res = await uninterceptedAxiosInstance.post(
      '/api/sessions/impersonate',
      {
        email
      }, {
      headers: {
        'access-token': authHeaders['access-token'],
        'token-type': authHeaders['token-type'],
        'client': authHeaders['client'],
        'expiry': authHeaders['expiry'],
        'uid': authHeaders['uid']
      }
    })

    if (res.headers['access-token']) {
      const authHeaders = {
        'access-token': res.headers['access-token'],
        'token-type': res.headers['token-type'],
        'client': res.headers['client'],
        'expiry': res.headers['expiry'],
        'uid': res.headers['uid']
      }
      window.localStorage.setItem('bktAccess', JSON.stringify(authHeaders));
    }

    return res
  } else {
    impersonateLogout()
    return { error: 'You are not allowed to impersonate a user' }
  }
}

// const service = new Service(axios);
//
// service.register({
//   onRequest(config) {
//     return config;
//   },
//   onSync(promise) {
//     return promise;
//   },
//   onResponse(response) {
//     return response;
//   },
//   async onResponseError(err) {
//     if (
//       (err.response.status === 402) &&
//       err.config &&
//       !err.config.hasRetriedRequest
//     ) {
//       // await router.push("/u/sign_in");
//       window.location.href = "/u/sign_in";
//     }
//   },
// });

export default axios;
