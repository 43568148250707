export const interviewFormTypeToStr = {
  employee: 'Interviewee (IE)',
  manager: 'Interviewer (IR)',
  both: 'Both',
  cross: 'Cross review',
  pairs: 'Feedback 360',
  survey: 'Survey'
}

export const AccessLevelList = [
  {
    title: "Employee",
    value: "employee",
  },
  {
    title: "Manager",
    value: "manager",
  },
  {
    title: "Manager creator",
    value: "manager_creator",
  },
  {
    title: "Admin",
    value: "admin",
  },
]
export const AccessLevelListSuperAdmin = [
  {
    title: "Employee",
    value: "employee",
  },
  {
    title: "Manager",
    value: "manager",
  },
  {
    title: "Manager creator",
    value: "manager_creator",
  },
  {
    title: "Admin",
    value: "admin",
  },
  {
    title: "Super admin",
    value: "super_admin",
  },
]

export const InterviewQuestionType = {
  CHAPTER: 'InterviewApp::Questions::Chapter',
  PARAGRAPH: 'InterviewApp::Questions::Paragraph',
  OPEN: 'InterviewApp::Questions::Open',
  RATING: 'InterviewApp::Questions::Rating',
  MCQ: 'InterviewApp::Questions::Mcq',
  CREATE_ROADMAP: 'InterviewApp::Questions::CreateRoadmap',
  UPDATE_ROADMAP: 'InterviewApp::Questions::UpdateRoadmap',
}

export const LearnInputType = {
  CHECKBOX: 'Learn::Inputs::CheckboxQuestion',
  CONFIRMATION: 'Learn::Inputs::Confirmation',
  OPEN: 'Learn::Inputs::OpenQuestion',
  PARAGRAPH: 'Learn::Inputs::Paragraph',
  SIMPLE_TEXT: 'Learn::Inputs::SimpleText',
}

export const InterviewOneToOneLabels = {
  INTERVIEWEE: 'Employee',
  INTERVIEWER: 'Manager',
  CROSSED: 'Crossed',
}

export const InterviewStatuses = {
  NOT_STARTED: 'not_started',
  NOT_AVAILABLE_YET: 'not_available_yet',
  IN_PROGRESS: 'in_progress',
  SUBMITTED: 'submitted',
  DISABLED: 'disabled',
}