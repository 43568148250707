// JS
import loadPolymorph from '@/plugins/polymorph.js';
import loadFonts from '@/plugins/webfontloader.js';
import loadQuillEditorOptions from '@/plugins/quillEditor.js';
import vuetify from './vuetify';
import pinia from '@/plugins/pinia.js';
import i18n from '@/plugins/i18n.js';
import router from '@/router/index.js';
import Vue3Lottie from 'vue3-lottie';
import vue3GoogleLogin from 'vue3-google-login';
import directivesClickOutsideBooklet from '@/plugins/directives.js';
import modal from '@/tools/modal.js';
import filters from '@/tools/filters.js';
import SvnUiLibrary from 'svn-ui-library/components';
import { Intersect } from 'vuetify/directives';
import { VueQueryPlugin } from '@tanstack/vue-query';

// STYLES
import 'svn-ui-library/style.css';
// import "vue3-lottie/dist/style.css";
import '../index.css';
import '../typography.scss';

export function registerPlugins(app) {
  loadPolymorph();
  loadFonts();
  loadQuillEditorOptions(app);

  app.use(vuetify);
  app.use(pinia);
  app.use(i18n);
  app.use(router);
  app.use(SvnUiLibrary);
  app.use(Vue3Lottie);
  app.use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  });
  app.use(VueQueryPlugin);

  // directives
  app.directive('click-outside-booklet', directivesClickOutsideBooklet);
  app.directive('intersect', Intersect);

  // global variables https://stackoverflow.com/questions/65184107/how-to-use-vue-prototype-or-global-variable-in-vue-3
  app.provide('$modal', modal);
  app.config.globalProperties.$modal = modal;
  app.config.globalProperties.$filters = filters;
}
